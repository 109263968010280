import { Component, Input } from '@angular/core'

// This component displays an address in the more complete version available.
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {
  @Input() streetNumber: string
  @Input() route: string
  @Input() locality: string
  @Input() postalCode: string
  @Input() country: string
  @Input() addressName: string
}
