import { Component, Input, OnInit } from '@angular/core'
import { RegionalDirectorate } from '../../../common/interfaces/regional-directorate.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-regional-directorate-table',
  templateUrl: './regional-directorate-table.component.html',
  styleUrls: ['./regional-directorate-table.component.scss']
})
export class RegionalDirectorateTableComponent implements OnInit {
  @Input() regionalDirectorates: RegionalDirectorate[]
  regionalDirectorateToDelete: RegionalDirectorate

  role: Role
  Role = Role

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.role = this.authService.getRole()
  }
}
