import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core'
import { Invoice } from '../../../common/interfaces/invoice.interface'
import { Receipt } from '../../../common/interfaces/receipt.interface'
import { HasDropdown } from '../../../common/base-components/has-dropdown'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'
import { ResourceService } from '../../../common/services/resource.service'
import { environment } from '../../../../environments/environment'

import * as moment from 'moment'

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
  providers: [ResourceService]
})
export class InvoiceTableComponent
  extends HasDropdown
  implements OnInit, OnChanges {
  @Input() invoices: Invoice[]
  @Input() showAgency = true
  @Input() showOrderNumber = true
  @Input() showReceipts = true
  @Input() showOpen = true
  @Input() navigateToOnDeleteInvoice = '/invoices'
  @Output() orderByChanged: EventEmitter<{
    orderBy: string
    orderByDesc: boolean
  }> = new EventEmitter()
  @Output() paymentDateChanged: EventEmitter<void> = new EventEmitter()

  invoiceToDelete: Invoice
  invoiceToPay: Invoice

  role: string
  Role = Role

  orderByDesc = false
  orderBy: string

  downloadLoading: number
  printLoading: number

  payInvoiceForm: FormGroup = this.formBuilder.group({
    paymentDate: [null, Validators.required]
  })

  constructor(
    elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private resourceService: ResourceService
  ) {
    super(elementRef)
  }

  ngOnInit() {
    this.role = this.authService.getRole()
    this.invoices = this.invoices.map((i) => {
      i.isLate =
        !i.paymentDate && moment(i.paymentDeadlineDate).isSameOrBefore(moment())
      return i
    })
  }

  ngOnChanges() {
    this.calculateInvoiceAmounts()
  }

  async print(invoice: Invoice, index: number) {
    this.printLoading = index
    const invoicePath: string = await this.resourceService
      .show('invoices', invoice.id, 'download')
      .toPromise()
      .then((res) => res.path)

    delete this.printLoading
    window.open(environment.storagePath + invoicePath)
  }

  // Add totalReceiptAmounts prop to Invoice based on Receipts' amounts.
  calculateInvoiceAmounts(): void {
    this.invoices.forEach((i: Invoice) => {
      i.totalReceiptAmounts = i.receipts.reduce(
        (prev: number, curr: Receipt) => prev + curr.amount,
        0
      )
    })
  }

  openPaymentModal(invoice: Invoice, event: Event) {
    event.preventDefault()
    this.invoiceToPay = invoice
  }

  order(property: string) {
    if (this.orderBy === property) {
      this.orderByDesc = !this.orderByDesc
    }
    this.orderBy = property
    this.orderByChanged.emit({
      orderBy: this.orderBy,
      orderByDesc: this.orderByDesc
    })
  }

  onInvoiceUpdated(updatedInvoice: Invoice): void {
    const invoice: Invoice = this.invoices.find(
      (i: Invoice) => i.id === updatedInvoice.id
    )
    invoice.paymentDate = updatedInvoice.paymentDate

    // Emit event to inform parent that values have changed
    this.paymentDateChanged.emit()
  }
}
