import { Component, OnInit, Input } from '@angular/core'
import { OrderLine } from '../../../common/interfaces/order-line.interface'

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.scss']
})
export class CategoryTableComponent implements OnInit {
  @Input() orderLines: OrderLine[]

  categories: { name: string; amount: number }[] = []

  constructor() {}

  ngOnInit() {
    // Group orderLines by category prop
    const orderLinesGroupedByCategory: {
      [key: string]: OrderLine[]
    } = this.orderLines.reduce((prev, curr) => {
      prev[curr.category] = prev[curr.category] || []
      prev[curr.category].push(curr)
      return prev
    }, {})

    // Calculate total orderLine amount amount per category
    Object.keys(orderLinesGroupedByCategory).forEach((category: string) => {
      const amount: number = orderLinesGroupedByCategory[category].reduce(
        (prev: number, curr: OrderLine) =>
          prev + curr.rawPrice * curr.rate * curr.quantity,
        0
      )
      this.categories.push({ name: category, amount })
    })
  }
}
