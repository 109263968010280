import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import * as moment from 'moment'

import { appConstants } from '../../../app.constants'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { Market } from '../../../common/interfaces/market.interface'
import { Range } from '../../../common/interfaces/range.interface'
import { RegionalDirectorate } from '../../../common/interfaces/regional-directorate.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-market-create-edit',
  templateUrl: './market-create-edit.component.html',
  styleUrls: ['./market-create-edit.component.scss']
})
export class MarketCreateEditComponent extends HasDatepicker implements OnInit {
  mode: string
  market: Market
  marketToDelete: Market
  loading: boolean

  initialSearchResults: { [key: string]: string[] }

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    number: ['', Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    interlocutor: '',
    legalDate: null,
    financialCommitment: [false, Validators.required],
    amount: null,
    returnPeriod: [10, Validators.required],
    regionalDirectorateIds: [],
    ranges: this.formBuilder.array([])
  })

  get rangesFormArray() {
    return this.form.get('ranges') as FormArray
  }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {
    super()
  }

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/markets',
          label: 'Marchés'
        },
        {
          label: 'Nouveau marché'
        }
      ])
    } else {
      // Edit mode.
      this.market = await this.resourceService
        .show('markets', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Market) => res)

      this.initialSearchResults = {
        regionalDirectorateIds: this.market.regionalDirectorates
          ? this.market.regionalDirectorates.map((rD: RegionalDirectorate) =>
              rD.id.toString()
            )
          : []
      }

      this.form.controls.name.setValue(this.market.name)
      this.form.controls.number.setValue(this.market.number)
      this.form.controls.startDate.setValue(
        this.formatStandardDate(
          moment(this.market.startDate).format('YYYY-MM-DD')
        )
      )
      this.form.controls.endDate.setValue(
        this.formatStandardDate(
          moment(this.market.endDate).format('YYYY-MM-DD')
        )
      )
      this.form.controls.interlocutor.setValue(this.market.interlocutor)
      this.form.controls.financialCommitment.setValue(
        !!this.market.financialCommitment
      )
      this.form.controls.amount.setValue(this.market.amount)
      this.form.controls.returnPeriod.setValue(this.market.returnPeriod)

      this.form.controls.legalDate.setValue(
        this.market.legalDate
          ? this.formatStandardDate(
              moment(this.market.legalDate).format('YYYY-MM-DD')
            )
          : null
      )
      this.form.controls.regionalDirectorateIds.setValue(
        this.initialSearchResults.regionalDirectorateIds
      )

      this.market.ranges.forEach((r: Range) => {
        const ranges = this.form.get('ranges') as FormArray
        ranges.push(
          this.formBuilder.group({
            name: [r.name, Validators.required],
            file: [r.file, Validators.required],
            rate: [r.rate, Validators.required],
            id: r.id
          })
        )
      })

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/markets',
          label: 'Marchés'
        },
        {
          label: this.market.name
        },
        {
          label: 'Editer'
        }
      ])
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('number', this.form.value.number)
    formData.append(
      'startDate',
      this.formatMyDatePickerDate(this.form.value.startDate)
    )
    formData.append(
      'endDate',
      this.formatMyDatePickerDate(this.form.value.endDate)
    )
    formData.append('interlocutor', this.form.value.interlocutor)
    formData.append(
      'legalDate',
      this.formatMyDatePickerDate(this.form.value.legalDate)
    )
    formData.append(
      'financialCommitment',
      this.form.value.financialCommitment ? '1' : '0'
    )
    formData.append(
      'amount',
      this.form.value.amount ? this.form.value.amount : 0
    )
    if (this.form.value.returnPeriod) {
      formData.append('returnPeriod', this.form.value.returnPeriod)
    }

    if (this.form.value.regionalDirectorateIds) {
      this.form.value.regionalDirectorateIds.forEach((id: string) => {
        formData.append('regionalDirectorateIds', id)
      })
    }

    // Ranges
    if (this.form.value.ranges) {
      this.form.value.ranges.forEach(
        (
          range: { name: string; file: any; rate: number; id?: number },
          index: number
        ) => {
          if (typeof range.file === 'string') {
            formData.append(
              'rangeObjects',
              JSON.stringify({
                name: range.name,
                rate: range.rate,
                file: range.file,
                id: range.id
              })
            )
          } else {
            formData.append(
              'rangeFile' + index,
              range.file.content,
              range.file.name
            )
            formData.append(
              'rangeObjects',
              JSON.stringify({
                name: range.name,
                rate: range.rate,
                id: range.id
              })
            )
          }
        }
      )
    }

    if (this.mode === 'create') {
      this.resourceService.store('markets', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(`Le marché a bien été enregistré`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/markets'])
        },
        (err: HttpErrorResponse) => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' + JSON.stringify(err.error.message),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('markets', this.market.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(`Le marché a bien été mis à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })

            this.router.navigate(['/markets'])
          },
          (err) => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  addRange(): void {
    const ranges = this.form.get('ranges') as FormArray
    ranges.push(
      this.formBuilder.group({
        name: ['', Validators.required],
        file: [null, Validators.required],
        rate: [null, Validators.required]
      })
    )
  }

  removeRange(index: number): void {
    const ranges = this.form.get('ranges') as FormArray
    ranges.removeAt(index)
  }

  onRangeFileSelected(file: File, index: number) {
    const ranges = this.form.get('ranges') as FormArray
    ranges.at(index).patchValue({ file })
  }

  onFileRemoved(index: number) {
    const ranges = this.form.get('ranges') as FormArray
    ranges.at(index).patchValue({ file: null })
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.form
      .get('regionalDirectorateIds')
      .setValue(
        searchResults
          .filter(
            (s: SearchResult) => s.resourceName === 'regionalDirectorates'
          )
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
