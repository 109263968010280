import { HostListener, ElementRef } from '@angular/core'

export class HasDropdown {
  public activeActionDropdown: number
  private elementRef: ElementRef

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef
  }

  toggleActionDropdown(index: number) {
    if (this.activeActionDropdown === index) {
      delete this.activeActionDropdown
    } else {
      this.activeActionDropdown = index
    }
  }

  // We track clicks to close dropdown if open and click outside
  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    if (this.activeActionDropdown >= 0) {
      const dropdowns: NodeList = this.elementRef.nativeElement.querySelectorAll(
        '.dropdown'
      )

      let clickedOut = true
      dropdowns.forEach((d: Node) => {
        if (d.contains(target)) {
          clickedOut = false
        }
      })

      if (clickedOut) {
        delete this.activeActionDropdown
      }
    }
  }
}
