import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'
import { Agency } from '../../../common/interfaces/agency.interface'
import { Referent } from '../../../common/interfaces/referent.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-referent-create-edit',
  templateUrl: './referent-create-edit.component.html',
  styleUrls: ['./referent-create-edit.component.scss']
})
export class ReferentCreateEditComponent implements OnInit {
  mode: string
  referent: Referent
  referentToDelete: Referent
  agencies: Agency[]
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    agencyId: ['', Validators.required]
  })
  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    this.resourceService
      .list('agencies', { withoutPagination: 'true' })
      .subscribe((res: Agency[]) => {
        this.agencies = res
      })

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/referents',
          label: `Donneurs d'ordres`
        },
        { label: `Nouveau donneur d'ordres` }
      ])
    } else {
      // Edit mode.
      this.referent = await this.resourceService
        .show('referents', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Referent) => res)

      // Prevent to change Agency if Referent has a Statement.
      if (this.referent.statementCount) {
        this.form.get('agencyId').disable()
      }

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/referents',
          label: `Donneurs d'ordres`
        },
        { label: this.referent.name },
        { label: `Editer` }
      ])

      this.form.controls.name.setValue(this.referent.name)
      this.form.controls.email.setValue(this.referent.email)
      this.form.controls.agencyId.setValue(this.referent.agency.id)
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('email', this.form.value.email)
    formData.append(
      'agencyId',
      this.form.value.agencyId || this.referent.agency.id
    )

    if (this.mode === 'create') {
      this.resourceService.store('referents', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(
            `le donneur d'ordres a bien été enregistrée`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          this.router.navigate(['/referents'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' + JSON.stringify(err.error.message),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('referents', this.referent.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(
              `le donneur d'ordres a bien été mise à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )

            this.router.navigate(['/referents'])
          },
          err => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
