import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'
import { Referent } from '../../../common/interfaces/referent.interface'

@Component({
  selector: 'app-referent-create-form',
  templateUrl: './referent-create-form.component.html',
  styleUrls: ['./referent-create-form.component.scss'],
  providers: [ResourceService]
})
export class ReferentCreateFormComponent implements OnChanges {
  @Input() agencyId: number
  @Output() referentStored: EventEmitter<Referent> = new EventEmitter()
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    agencyId: ['', Validators.required]
  })
  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnChanges() {
    this.form.get('agencyId').setValue(this.agencyId)
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('email', this.form.value.email)
    formData.append('agencyId', this.form.value.agencyId)

    this.resourceService.store('referents', formData).subscribe(
      (referentCreated: Referent) => {
        this.loading = false
        // We reset form but we keep agencyId.
        this.form.setValue({ agencyId: this.agencyId, name: '', email: '' })
        this.referentStored.emit(referentCreated)
      },
      err => {
        this.loading = false
        this.flashMessagesService.show(
          'Erreur :' + err.error.message.map(m => Object.values(m.constraints)),
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      }
    )
  }
}
