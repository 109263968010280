import { Component, OnInit } from '@angular/core'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/statements'])
  }
}
