import { Component, Input, OnInit } from '@angular/core'
import { Technician } from '../../../common/interfaces/technician.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-technician-table',
  templateUrl: './technician-table.component.html',
  styleUrls: ['./technician-table.component.scss']
})
export class TechnicianTableComponent implements OnInit {
  @Input() technicians: Technician[]
  technicianToDelete: Technician

  role: Role
  Role = Role

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.role = this.authService.getRole()
  }
}
