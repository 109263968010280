import { Component, Output, EventEmitter, Input } from '@angular/core'
import { Address, AddressComponent } from 'angular-google-place'
import { AddressObject } from '../../../common/interfaces/address-object.interface'

@Component({
  selector: 'app-google-places-input',
  templateUrl: './google-places-input.component.html',
  styleUrls: ['./google-places-input.component.scss']
})
export class GooglePlacesInputComponent {
  @Input() placeholder = 'Chercher une adresse...'
  @Input() required = false
  @Input() isAddressSet = false
  @Input() showErrors = false
  @Output() addressSet: EventEmitter<AddressObject> = new EventEmitter()

  // Triggered on choosing Google Places address
  setAddress(address: Address): void {
    const streetNumber = address.address_components.find(
      (a: AddressComponent) => a.types.includes('street_number')
    )
    const route = address.address_components.find((a: AddressComponent) =>
      a.types.includes('route')
    )
    const locality = address.address_components.find((a: AddressComponent) =>
      a.types.includes('locality')
    )
    const postalCode = address.address_components.find((a: AddressComponent) =>
      a.types.includes('postal_code')
    )
    const country = address.address_components.find((a: AddressComponent) =>
      a.types.includes('country')
    )

    const addressObject: AddressObject = {
      addressName: address.name
    }

    if (streetNumber) {
      addressObject.streetNumber = streetNumber.long_name
    }
    if (route) {
      addressObject.route = route.long_name
    }
    if (locality) {
      addressObject.locality = locality.long_name
    }
    if (postalCode) {
      addressObject.postalCode = postalCode.long_name
    }
    if (country) {
      addressObject.country = country.long_name
    }

    this.isAddressSet = true
    this.addressSet.emit(addressObject)
  }
}
