import { Component, OnInit } from '@angular/core'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Invoice } from '../../../common/interfaces/invoice.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { FormGroup, FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { IMyDate } from 'mydatepicker'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { environment } from '../../../../environments/environment'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent extends HasDatepicker implements OnInit {
  paginator: Paginator<Invoice>
  queryParams: object

  lastFormValues: any
  totalAmounts: number

  role: string
  Role = Role
  loading = false

  filterForm: FormGroup = this.formBuilder.group({
    invoiceIds: [],
    receiptIds: [],
    statementIds: [],
    paymentStatus: '',
    editionDateFrom: '',
    editionDateTo: '',
    paymentDeadlineDateFrom: '',
    paymentDeadlineDateTo: '',
    orderBy: null,
    orderByDesc: null,
    page: ''
  })

  // Independent date pickers.
  dates: {
    editionDateFrom: { date: IMyDate }
    editionDateTo: { date: IMyDate }
    paymentDeadlineDateFrom: { date: IMyDate }
    paymentDeadlineDateTo: { date: IMyDate }
  } = {
    editionDateFrom: { date: null },
    editionDateTo: { date: null },
    paymentDeadlineDateFrom: { date: null },
    paymentDeadlineDateTo: { date: null }
  }

  constructor(
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {
    super()
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Factures'
      }
    ])
  }

  ngOnInit() {
    this.role = this.authService.getRole()

    this.activatedRoute.queryParams.subscribe(async queryParams => {
      this.setDateObjects(queryParams)

      this.queryParams = queryParams

      if (queryParams.toXLS) {
        this.resourceService
          .list('invoices', { ...queryParams, toXLS: true })
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/invoices'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        delete this.totalAmounts
        delete this.paginator
        this.loading = true
        this.paginator = (await this.resourceService
          .list('invoices', queryParams)
          .toPromise()
          .then((res: Paginator<Invoice>) => res)
          .catch(err => (this.loading = false))) as Paginator<Invoice>

        // Calculate Invoice total amounts
        this.totalAmounts = await this.resourceService
          .list('invoices', { ...queryParams, calculateTotal: true })
          .toPromise()
          .then((res: { total: number }) => res.total)

        this.loading = false
      }

      this.filterForm.valueChanges.subscribe(async formValues => {
        // Return to page 1 if we changed our filters (changes in form that are not only the page).
        if (
          this.lastFormValues &&
          formValues !== this.lastFormValues &&
          formValues.page === this.lastFormValues.page
        ) {
          formValues.page = 1
        }
        this.lastFormValues = formValues
        this.router.navigate([], {
          queryParams: formValues
        })
      })
    })
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.filterForm.patchValue({
      statementIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'statements')
        .map((s: SearchResult) => s.id.toString()),
      invoiceIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'invoices')
        .map((s: SearchResult) => s.id.toString()),
      receiptIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'receipts')
        .map((s: SearchResult) => s.id.toString())
    })
  }

  onOrderByChanged({
    orderBy,
    orderByDesc
  }: {
    orderBy: string
    orderByDesc: boolean
  }) {
    this.filterForm.patchValue({
      orderBy,
      orderByDesc: orderByDesc ? 'true' : null
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
