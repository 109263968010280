import { Component, OnInit } from '@angular/core'
import { RegionalDirectorate } from '../../../common/interfaces/regional-directorate.interface'
import { Validators, FormGroup, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-regional-directorate-create-edit',
  templateUrl: './regional-directorate-create-edit.component.html',
  styleUrls: ['./regional-directorate-create-edit.component.scss']
})
export class RegionalDirectorateCreateEditComponent implements OnInit {
  mode: string
  regionalDirectorate: RegionalDirectorate
  regionalDirectorateToDelete: RegionalDirectorate
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/regional-directorates',
          label: 'Directions Régionales'
        },
        {
          label: 'Nouvelle Direction Regionale'
        }
      ])
    } else {
      // Edit mode.
      this.regionalDirectorate = await this.resourceService
        .show('regional-directorates', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: RegionalDirectorate) => res)

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/regional-directorates',
          label: 'Directions Régionales'
        },
        {
          label: this.regionalDirectorate.name
        },
        {
          label: 'Editer'
        }
      ])

      this.form.controls.name.setValue(this.regionalDirectorate.name)
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)

    if (this.mode === 'create') {
      this.resourceService.store('regional-directorates', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(`La DR a bien été enregistrée`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/regional-directorates'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('regional-directorates', this.regionalDirectorate.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(`La DR a bien été mise à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })

            this.router.navigate(['/regional-directorates'])
          },
          err => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map(m => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
