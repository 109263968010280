import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { FlashMessagesService } from 'angular2-flash-messages'
import { User } from '../../../common/interfaces/user.interface'
import { appConstants } from '../../../app.constants'
import { AuthService } from '../../../common/services/auth.service'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required]
  })

  logoPath: string = environment.logoPath

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  submitForm(loginForm: FormGroup) {
    this.authService
      .login(loginForm.value.email, loginForm.value.password)
      .subscribe(
        (res: { role: string; userId: number }) => {
          this.authService.me().subscribe((userRes: User) => {
            this.authService.currentUser.next(userRes)
          })

          return this.router.navigate(['/'])
        },
        (err) => {
          this.flashMessagesService.show(err.error.message, {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.loginForm.reset()
        }
      )
  }
}
