import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener
} from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { Subscription } from 'rxjs'

import { AuthService } from './common/services/auth.service'
import { User } from './common/interfaces/user.interface'
import { EventService } from './common/services/event.service'
import { ViewportService } from './common/services/viewport.service'
import { appConstants } from './app.constants'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../assets/styles/main.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  private eventSubscriptions = new Subscription()
  private subscription = new Subscription()

  private currentUser: User
  isLogin: boolean
  isTouchResolution: boolean
  path: string

  constructor(
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private viewportService: ViewportService
  ) {
    this.eventSubscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.eventService.routeChanged.next({ url: event.url })
        }
      })
    )
  }

  ngOnInit() {
    this.setIsTouchResolution()

    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
    this.subscription.add(
      this.eventService.routeChanged.subscribe(routeChanged => {
        // Scroll top
        window.scrollTo(0, 0)
        this.path = routeChanged.url.includes('?')
          ? routeChanged.url.substring(0, routeChanged.url.indexOf('?'))
          : routeChanged.url
        this.isLogin = this.path.includes('/login')
        if (this.authService.check() && !this.currentUser) {
          this.authService.me().subscribe((userRes: User) => {
            this.authService.currentUser.next(userRes)
          })
        }
      })
    )
    this.viewportService.isTouchResolution.subscribe(newValue => {
      this.isTouchResolution = newValue
    })
  }
  @HostListener('window:resize')
  onResize() {
    this.setIsTouchResolution()
  }

  setIsTouchResolution(): void {
    this.viewportService.isTouchResolution.next(
      window.innerWidth < appConstants.TOUCH_BREAKPOINT
    )
  }
}
