import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { Role } from '../enums/role.enum'
import { User } from '../interfaces/user.interface'

@Injectable()
export class AuthService {
  token: string
  role: string
  baseUrl = environment.apiBaseUrl + 'auth/'

  public currentUser = new ReplaySubject(1)

  constructor(private http: HttpClient) {
    // Set token if saved in local storage
    this.token = localStorage.getItem('token')
    this.role = localStorage.getItem('role')
  }

  login(
    email: string,
    password: string
  ): Observable<{ role: string; userId: number } | boolean> {
    return this.http.post(this.baseUrl + 'login', { email, password }).pipe(
      map((res: { accessToken: string; role: string; userId: number }) => {
        const token = res && res.accessToken
        const role = res && res.role
        if (token) {
          this.token = token
          this.role = role

          // Store JWT token and Role in local storage
          localStorage.setItem('token', token)
          localStorage.setItem('role', role)
          return { role: this.role, userId: res.userId }
        }
        return false
      })
    )
  }

  logout(): void {
    this.token = null
    localStorage.removeItem('token')
    localStorage.removeItem('role')
  }

  check(): boolean {
    return !!this.getToken()
  }

  getToken(): string {
    return localStorage.getItem('token')
  }

  getRole(): Role {
    return localStorage.getItem('role') as Role
  }

  me(): Observable<User> {
    return this.http.get(this.baseUrl + 'me').pipe(map((res: User) => res))
  }
}
