import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import * as moment from 'moment'
import { combineLatest } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { Role } from '../../../common/enums/role.enum'
import { StatementType } from '../../../common/enums/statement-type.enum'
import { Invoice } from '../../../common/interfaces/invoice.interface'
import { OrderLine } from '../../../common/interfaces/order-line.interface'
import { Receipt } from '../../../common/interfaces/receipt.interface'
import { Reminder } from '../../../common/interfaces/reminder.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-statement-detail',
  templateUrl: './statement-detail.component.html',
  styleUrls: ['./statement-detail.component.scss'],
  providers: [ResourceService]
})
export class StatementDetailComponent implements OnInit {
  @ViewChild('emailInput') emailInputEl: ElementRef

  statement: Statement
  statementToSendReminder: Statement
  invoiceToCreateReceipt: Invoice

  downloadLoading = false
  emailLoading = false
  showEmailModal = false

  emailForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  })
  role: Role

  Role = Role
  StatementType = StatementType

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.role = this.authService.getRole()

    combineLatest(
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ).subscribe(
      async ([params, queryParams]: [
        { id: string },
        { [key: string]: string }
      ]) => {
        this.statement = await this.getStatement(params.id)
        this.emailForm.patchValue({ email: this.statement.referent.email })

        const directOpen = this.activatedRoute.snapshot.queryParams.directOpen

        if (directOpen && directOpen === 'addReceipt') {
          this.invoiceToCreateReceipt = this.statement.invoice
        }

        this.breadcrumbService.breadcrumbLinks.next([
          {
            path: '/statements',
            label: 'Relevés'
          },
          {
            path: '/statements/' + this.statement.id,
            label: this.statement.orderNumber
          }
        ])
      }
    )
  }

  async getStatement(id: string | number): Promise<Statement> {
    return this.resourceService
      .show('statements', id)
      .toPromise()
      .then((statementRes: Statement) => {
        const statement = statementRes
        statement.totalOrderLineAmounts = statement.orderLines.reduce(
          (prev: number, curr: OrderLine) =>
            prev + curr.rawPrice * curr.rate * curr.quantity,
          0
        )
        statement.isLate =
          statement.sendingDate &&
          !statement.returnDate &&
          moment(statement.sendingDate)
            .add(statement.referent.agency.paymentPeriod, 'days')
            .isBefore(moment())

        return statement
      })
  }

  async download() {
    this.downloadLoading = true

    const statementPath: string = await this.resourceService
      .list('statements', { statementIds: this.statement.id, toZIP: 'true' })
      .toPromise()
      .then((res) => res.path)

    delete this.downloadLoading
    window.open(environment.storagePath + statementPath)
  }

  duplicateStatement(statementId: string | number): void {
    this.resourceService.duplicate('statements', statementId).subscribe(
      (createdItem: { id: number }) => {
        this.flashMessagesService.show(
          `Le relevé a été dupliqué avec succès. Veuillez éditer les champs pour le personnaliser.`,
          {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )

        this.router.navigate(['statements', createdItem.id, 'edit'])
      },
      (err) =>
        this.flashMessagesService.show(
          `Une erreur est survenue. Impossible de dupliquer l'élément.`,
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
    )
  }

  async sendByEmail() {
    this.emailLoading = true

    this.resourceService
      .list('statements', {
        statementIds: this.statement.id,
        toEmail: this.emailForm.value.email
      })
      .toPromise()
      .then(
        () => {
          this.showEmailModal = false
          this.emailLoading = false

          // Change query params to force reload on lists

          this.flashMessagesService.show(
            `Le relevé a bien été envoyé par email.`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        },
        (err) => {
          this.showEmailModal = false
          this.emailLoading = false

          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }

  deleteReceipt(receipt: Receipt) {
    this.resourceService.delete('receipts', receipt.id).subscribe(async () => {
      this.flashMessagesService.show(`La FSS a bien été supprimé.`, {
        cssClass: 'notification is-success',
        timeout: appConstants.FLASH_MESSAGE_TIMEOUT
      })

      const index = this.statement.invoice.receipts.indexOf(receipt)
      this.statement.invoice.receipts.splice(index, 1)

      // Update statement and invoice value
      this.statement = await this.getStatement(this.statement.id)
    })
  }

  convertIntoContradictory(statementId: number) {
    this.resourceService
      .patch('statements', statementId, 'convert-into-contradictory')
      .subscribe((res) => {
        this.flashMessagesService.show(
          `Le RP a bien été converti en RC. Vous pouvez désormais ajouter des factures.`,
          {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )

        this.router.navigate(['statements', statementId], {
          queryParams: {
            reload: new Date().toISOString()
          }
        })
      })
  }

  promptShare() {
    this.showEmailModal = true
    setTimeout(() => this.emailInputEl.nativeElement.focus(), 0)
  }

  onCreateReceipt(receipt: {
    name: string
    amount: number
    createAnotherOne: boolean
  }): void {
    const formData = new FormData()
    formData.set('name', receipt.name)
    formData.set('amount', receipt.amount.toString())
    formData.set('invoiceId', this.statement.invoice.id.toString())

    this.resourceService
      .store(`receipts`, formData)
      .subscribe(async (receiptRes: Receipt) => {
        this.statement.invoice.receipts.push(receiptRes)

        // Delete invoiceToCreateReceipt or update it with new receipt pushed.
        if (!receipt.createAnotherOne) {
          delete this.invoiceToCreateReceipt
        } else {
          this.invoiceToCreateReceipt = this.statement.invoice
        }

        // Update statement and invoice value
        this.statement = await this.getStatement(this.statement.id)
      })
  }

  onCreateReminder(statement: Statement, content: string): void {
    const formData = new FormData()
    formData.set('content', content)
    this.resourceService
      .store(`statements/${statement.id}/reminders`, formData)
      .subscribe((reminderRes: Reminder) => {
        statement.reminders.push(reminderRes)
      })
    delete this.statementToSendReminder
  }
}
