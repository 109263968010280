import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { User } from '../../../common/interfaces/user.interface'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Statement } from '@angular/compiler'
import { ActivatedRoute } from '@angular/router'
import { Role } from '../../../common/enums/role.enum'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [ResourceService]
})
export class UserDetailComponent implements OnInit {
  user: User
  userToDelete: User
  paginator: Paginator<Statement>

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: { id: string }) => {
      this.resourceService
        .show('users', params.id)
        .subscribe((userRes: User) => {
          this.user = userRes

          this.breadcrumbService.breadcrumbLinks.next([
            {
              path: '/users',
              label: 'Collaborateurs'
            },
            {
              label: this.user.name
            }
          ])
        })

      // Subscribe to queryParams for page changes and get user's statements.
      this.activatedRoute.queryParams.subscribe(async queryParams => {
        this.resourceService
          .list('statements', { ...queryParams, ...{ userIds: [params.id] } })
          .subscribe((statementRes: Paginator<Statement>) => {
            this.paginator = statementRes
          })
      })
    })
  }
}
