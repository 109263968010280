import { Component, OnInit } from '@angular/core'
import { Technician } from '../../../common/interfaces/technician.interface'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-technician-create-edit',
  templateUrl: './technician-create-edit.component.html',
  styleUrls: ['./technician-create-edit.component.scss']
})
export class TechnicianCreateEditComponent implements OnInit {
  mode: string
  technician: Technician
  technicianToDelete: Technician
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    initials: ['', Validators.required]
  })
  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/technicians',
          label: `Intervenants`
        },
        { label: 'Nouvel intervenant' }
      ])
    } else {
      // Edit mode.
      this.technician = await this.resourceService
        .show('technicians', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Technician) => res)

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/technicians',
          label: `Intervenants`
        },
        {
          label: this.technician.name
        },
        { label: 'Editer' }
      ])

      this.form.controls.name.setValue(this.technician.name)
      this.form.controls.initials.setValue(this.technician.initials)
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('initials', this.form.value.initials)

    if (this.mode === 'create') {
      this.resourceService.store('technicians', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(
            `La nature de dépenses a bien été enregistrée`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          this.router.navigate(['/technicians'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('technicians', this.technician.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(
              `La nature de dépenses a bien été mise à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )

            this.router.navigate(['/technicians'])
          },
          err => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map(m => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
