import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { HomeComponent } from './pages/home/home.component'
import { AuthGuard } from './common/guards/auth.guard'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { IsAdminGuard } from './common/guards/is-admin.guard'
import { UserListComponent } from './resources/user/user-list/user-list.component'
import { Error404Component } from './pages/error404/error404.component'
import { StatementListComponent } from './resources/statement/statement-list/statement-list.component'
import { StatementCreateEditComponent } from './resources/statement/statement-create-edit/statement-create-edit.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { InvoiceCreateEditComponent } from './resources/invoice/invoice-create-edit/invoice-create-edit.component'
import { MarketListComponent } from './resources/market/market-list/market-list.component'
import { MarketCreateEditComponent } from './resources/market/market-create-edit/market-create-edit.component'
import { StatementDetailComponent } from './resources/statement/statement-detail/statement-detail.component'
import { AgencyListComponent } from './resources/agency/agency-list/agency-list.component'
import { AgencyCreateEditComponent } from './resources/agency/agency-create-edit/agency-create-edit.component'
import { AgencyDetailComponent } from './resources/agency/agency-detail/agency-detail.component'
import { ExpenseListComponent } from './resources/expense/expense-list/expense-list.component'
import { ExpenseCreateEditComponent } from './resources/expense/expense-create-edit/expense-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { TechnicianListComponent } from './resources/technician/technician-list/technician-list.component'
import { TechnicianCreateEditComponent } from './resources/technician/technician-create-edit/technician-create-edit.component'
import { RegionalDirectorateListComponent } from './resources/regional-directorate/regional-directorate-list/regional-directorate-list.component'
import { RegionalDirectorateCreateEditComponent } from './resources/regional-directorate/regional-directorate-create-edit/regional-directorate-create-edit.component'
import { NatureListComponent } from './resources/nature/nature-list/nature-list.component'
import { NatureCreateEditComponent } from './resources/nature/nature-create-edit/nature-create-edit.component'
import { TypeListComponent } from './resources/type/type-list/type-list.component'
import { TypeCreateEditComponent } from './resources/type/type-create-edit/type-create-edit.component'
import { SupplierListComponent } from './resources/supplier/supplier-list/supplier-list.component'
import { SupplierCreateEditComponent } from './resources/supplier/supplier-create-edit/supplier-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { MonitoringComponent } from './monitoring/monitoring.component'
import { IsAtLeastManagerGuard } from './common/guards/is-at-least-manager.guard'
import { IsAtLeastSecretaryGuard } from './common/guards/is-at-least-secretary.guard'
import { IsNotManagerGuard } from './common/guards/is-not-manager.guard'

const routes: Routes = [
  // Auth
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },

  // Pages
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // Users
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'users/create',
    component: UserCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'users/:id/edit',
    component: UserCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard]
  },

  // Statements
  {
    path: 'statements',
    component: StatementListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'statements/create',
    component: StatementCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'statements/:id/edit',
    component: StatementCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'statements/:id',
    component: StatementDetailComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },

  // Invoices
  {
    path: 'invoices',
    component: InvoiceListComponent,
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'invoices/create',
    component: InvoiceCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAtLeastSecretaryGuard]
  },
  {
    path: 'invoices/:id/edit',
    component: InvoiceCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAtLeastSecretaryGuard]
  },

  // Markets
  {
    path: 'markets',
    component: MarketListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'markets/create',
    component: MarketCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'markets/:id/edit',
    component: MarketCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Suppliers
  {
    path: 'suppliers',
    component: SupplierListComponent,
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'suppliers/create',
    component: SupplierCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'suppliers/:id/edit',
    component: SupplierCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },

  // Agencies
  {
    path: 'agencies',
    component: AgencyListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'agencies/create',
    component: AgencyCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'agencies/:id/edit',
    component: AgencyCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'agencies/:id',
    component: AgencyDetailComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },

  // Expenses
  {
    path: 'expenses',
    component: ExpenseListComponent,
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'expenses/create',
    component: ExpenseCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'expenses/:id/edit',
    component: ExpenseCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },

  // Referents
  {
    path: 'referents',
    component: ReferentListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'referents/create',
    component: ReferentCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'referents/:id/edit',
    component: ReferentCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },

  // Technician
  {
    path: 'technicians',
    component: TechnicianListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'technicians/create',
    component: TechnicianCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'technicians/:id/edit',
    component: TechnicianCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Regional directorate
  {
    path: 'regional-directorates',
    component: RegionalDirectorateListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'regional-directorates/create',
    component: RegionalDirectorateCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'regional-directorates/:id/edit',
    component: RegionalDirectorateCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Natures
  {
    path: 'natures',
    component: NatureListComponent,
    canActivate: [AuthGuard, IsAtLeastManagerGuard]
  },
  {
    path: 'natures/create',
    component: NatureCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'natures/:id/edit',
    component: NatureCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Types
  {
    path: 'types',
    component: TypeListComponent,
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'types/create',
    component: TypeCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'types/:id/edit',
    component: TypeCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsNotManagerGuard]
  },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    canActivate: [AuthGuard]
  },

  // 404
  {
    path: '404',
    component: Error404Component
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
