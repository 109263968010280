import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute } from '@angular/router'
import { Agency } from '../../../common/interfaces/agency.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { AuthService } from '../../../common/services/auth.service'
import { Role } from '../../../common/enums/role.enum'

@Component({
  selector: 'app-agency-detail',
  templateUrl: './agency-detail.component.html',
  styleUrls: ['./agency-detail.component.scss'],
  providers: [ResourceService]
})
export class AgencyDetailComponent implements OnInit {
  agency: Agency
  agencyToDelete: Agency
  paginator: Paginator<Statement>

  role: Role
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.role = this.authService.getRole()
    this.activatedRoute.params.subscribe((params: { id: string }) => {
      this.resourceService
        .show('agencies', params.id)
        .subscribe((agencyRes: Agency) => {
          this.agency = agencyRes

          this.breadcrumbService.breadcrumbLinks.next([
            {
              path: '/agencies',
              label: 'Agences'
            },
            {
              label: this.agency.name
            }
          ])
        })

      // Subscribe to queryParams for page changes and get agency's statements.
      this.activatedRoute.queryParams.subscribe(async queryParams => {
        this.resourceService
          .list('statements', { ...queryParams, ...{ agencyIds: [params.id] } })
          .subscribe((statementRes: Paginator<Statement>) => {
            this.paginator = statementRes
          })
      })
    })
  }
}
