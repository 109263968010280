import { Component, OnInit } from '@angular/core'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Agency } from '../../../common/interfaces/agency.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent implements OnInit {
  paginator: Paginator<Agency>
  role: Role
  Role = Role

  filterForm: FormGroup = this.formBuilder.group({
    page: ''
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Agences'
      }
    ])
  }

  ngOnInit() {
    this.role = this.authService.getRole()

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.toXLS) {
        this.resourceService
          .list('agencies', { ...queryParams, toXLS: true })
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/agencies'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        this.resourceService
          .list('agencies', queryParams)
          .subscribe((res: Paginator<Agency>) => {
            this.paginator = res
          })
      }
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      this.router.navigate([], {
        queryParams: formValues
      })
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
