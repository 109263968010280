import { Component, Input, OnInit } from '@angular/core'
import { Agency } from '../../../common/interfaces/agency.interface'
import { AuthService } from '../../../common/services/auth.service'
import { Role } from '../../../common/enums/role.enum'

@Component({
  selector: 'app-agency-table',
  templateUrl: './agency-table.component.html',
  styleUrls: ['./agency-table.component.scss']
})
export class AgencyTableComponent implements OnInit {
  @Input() agencies: Agency[]
  agencyToDelete: Agency
  role: Role
  Role = Role
  constructor(private authService: AuthService) {}
  ngOnInit() {
    this.role = this.authService.getRole()
  }
}
