import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange
} from '@angular/core'
import { AuthService } from '../../common/services/auth.service'
import { Role } from '../../common/enums/role.enum'
import * as moment from 'moment'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() path: string
  role: string
  Role = Role

  showProjects: boolean
  showClients: boolean
  showSpending: boolean
  showManagement: boolean

  // Pre-set filters
  marketListDateFrom: string = moment()
    .subtract(1, 'year')
    .format('YYYY-MM-DD')
  marketListDateTo: string = moment().format('YYYY-MM-DD')
  monitoringDateFrom: string = moment()
    .startOf('month')
    .format('YYYY-MM-DD')
  monitoringDateTo: string = moment()
    .endOf('month')
    .format('YYYY-MM-DD')

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.role = this.authService.getRole()
  }

  ngOnChanges(change: { path?: SimpleChange }) {
    if (!change.path) {
      return
    } else {
      const path = change.path.currentValue
    }

    // Open correct dropdown based on current path.
    if (
      this.path.includes('/statements') ||
      this.path.includes('/invoices') ||
      this.path.includes('/natures') ||
      this.path.includes('/markets') ||
      this.path.includes('/technicians')
    ) {
      this.showProjects = true
    } else if (
      this.path.includes('/referents') ||
      this.path.includes('/agencies') ||
      this.path.includes('/regional-directorates')
    ) {
      this.showClients = true
    } else if (
      this.path.includes('/expenses') ||
      this.path.includes('/suppliers') ||
      this.path.includes('/types')
    ) {
      this.showSpending = true
    } else if (
      this.path.includes('/users') ||
      this.path.includes('/reporting')
    ) {
      this.showManagement = true
    }
  }
}
