import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker'
import { FormGroup } from '@angular/forms'

export class HasDatepicker {
  filterForm: FormGroup
  dates: object

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    height: '45px',
    selectorWidth: '310px',
    selectorHeight: '374px'
  }

  // Format YYYY-MM-DD to MyDatePicker format (today if date not specified)
  formatStandardDate(
    dateString = new Date().toISOString().substring(0, 10)
  ): { date: IMyDate } {
    // In case of NULL dateString (!! Different than empty), we return null to make datepicker empty
    if (!dateString) {
      return null
    }
    const spiltDateFrom = dateString.split('-')
    return {
      date: {
        year: parseInt(spiltDateFrom[0], 10),
        month: parseInt(spiltDateFrom[1], 10),
        day: parseInt(spiltDateFrom[2], 10)
      }
    }
  }

  // Format MyDatePicker format date to YYYY-MM-DD
  formatMyDatePickerDate(dateObject: IMyDateModel): string {
    return dateObject
      ? `${dateObject.date.year}-${dateObject.date.month
          .toString()
          .padStart(2, '0')}-${dateObject.date.day.toString().padStart(2, '0')}`
      : null
  }

  // Iterate through props and convert MyDatePicker dates to YYYY-MM-DD
  formatParamsWithMyDatePickerDates(params: object): object {
    Object.keys(params).forEach((key: any) => {
      if (this.isMyDatePickerDate(params[key])) {
        params[key] = this.formatMyDatePickerDate(params[key])
      }
    })

    return params
  }

  // Iterate through props and convert YYYY-MM-DD strings to MyDatePicker dates.
  formatParamsWithStandardDates(params: object): object {
    const formattedParams = {}
    Object.keys(params).forEach((key: any) => {
      formattedParams[key] =
        typeof params[key] === 'string' &&
        params[key].match(/^\d{4}-\d{2}-\d{2}$/)
          ? this.formatStandardDate(params[key])
          : params[key]
    })

    return formattedParams
  }

  // Returns true if arg is a mydatepicker date.
  isMyDatePickerDate(arg: any): arg is IMyDateModel {
    return arg && arg.jsdate !== undefined
  }

  // Set formatted value of corresponding date property of filterForm.
  onDateChanged(date: IMyDateModel, controlName: string): void {
    if (date.jsdate) {
      this.filterForm
        .get(controlName)
        .setValue(this.formatMyDatePickerDate(date))
    } else {
      this.filterForm.get(controlName).reset()
    }
  }

  // Iterate through this.dates and set formatted date if similar prop exists in params.
  setDateObjects(queryParams: object) {
    Object.keys(this.dates).forEach((key: string) => {
      if (queryParams[key]) {
        this.dates[key] = this.formatStandardDate(queryParams[key])
      }
    })
  }
}
