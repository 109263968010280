import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Referent } from '../../../common/interfaces/referent.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-referent-list',
  templateUrl: './referent-list.component.html',
  styleUrls: ['./referent-list.component.scss']
})
export class ReferentListComponent implements OnInit {
  paginator: Paginator<Referent>
  queryParams: object
  lastFormValues: { page?: string } = {}

  filterForm: FormGroup = this.formBuilder.group({
    referentIds: [],
    agencyIds: [],
    page: ''
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: `Donneurs d'ordres`
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async queryParams => {
      this.queryParams = queryParams
      this.paginator = await this.resourceService
        .list('referents', queryParams)
        .toPromise()
        .then((res: Paginator<Referent>) => res)
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      // Return to page 1 if we changed our filters (changes in form that are not only the page).
      if (
        formValues !== this.lastFormValues &&
        formValues.page === this.lastFormValues.page
      ) {
        formValues.page = 1
      }
      this.lastFormValues = formValues
      this.router.navigate([], {
        queryParams: formValues
      })
    })
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.filterForm.patchValue({
      referentIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'referents')
        .map((s: SearchResult) => s.id.toString()),
      agencyIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'agencies')
        .map((s: SearchResult) => s.id.toString())
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
