import { Component, OnInit } from '@angular/core'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Market } from '../../../common/interfaces/market.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { FormGroup, FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { IMyDate } from 'mydatepicker'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { environment } from '../../../../environments/environment'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss']
})
export class MarketListComponent extends HasDatepicker implements OnInit {
  paginator: Paginator<Market>
  queryParams: object

  lastFormValues: any
  loading = false

  filterForm: FormGroup = this.formBuilder.group({
    activeDateFrom: '',
    activeDateTo: '',
    orderBy: null,
    orderByDesc: null,
    page: ''
  })

  role: Role
  Role = Role

  // Independent datepickers
  dates: {
    activeDateFrom: { date: IMyDate }
    activeDateTo: { date: IMyDate }
  } = {
    activeDateFrom: { date: null },
    activeDateTo: { date: null }
  }

  constructor(
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    super()
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Marchés'
      }
    ])
  }

  async ngOnInit() {
    this.role = this.authService.getRole()

    this.activatedRoute.queryParams.subscribe(async queryParams => {
      this.setDateObjects(queryParams)

      if (queryParams.toXLS) {
        this.resourceService
          .list('markets', { ...queryParams, toXLS: true })
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/markets'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        this.loading = true
        this.paginator = (await this.resourceService
          .list('markets', queryParams)
          .toPromise()
          .then((res: Paginator<Market>) => res)
          .catch(err => (this.loading = false))) as Paginator<Market>

        this.loading = false
      }
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      // Return to page 1 if we changed our filters (changes in form that are not only the page).
      if (
        this.lastFormValues &&
        formValues !== this.lastFormValues &&
        formValues.page === this.lastFormValues.page
      ) {
        formValues.page = 1
      }
      this.lastFormValues = formValues
      this.router.navigate([], {
        queryParams: this.formatParamsWithMyDatePickerDates(formValues)
      })
    })
  }

  onOrderByChanged({
    orderBy,
    orderByDesc
  }: {
    orderBy: string
    orderByDesc: boolean
  }) {
    this.filterForm.patchValue({
      orderBy,
      orderByDesc: orderByDesc ? 'true' : null
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
