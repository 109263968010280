import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import * as moment from 'moment'

import { appConstants } from '../../../app.constants'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { Invoice } from '../../../common/interfaces/invoice.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-invoice-create-edit',
  templateUrl: './invoice-create-edit.component.html',
  styleUrls: ['./invoice-create-edit.component.scss'],
  providers: [ResourceService]
})
export class InvoiceCreateEditComponent
  extends HasDatepicker
  implements OnInit {
  mode: string
  invoice: Invoice
  invoiceToDelete: Invoice
  loading: boolean

  initialSearchResults: { [key: string]: string[] }

  conflictErrorMessage =
    `Erreur : Le relevé sélectionné a déjà une facture. Veuillez sélectionner un relevé qui` +
    ` n'a pas de facture ou bien supprimer la facture actuelle`

  form: FormGroup = this.formBuilder.group({
    number: ['', Validators.required],
    editionDate: [
      this.formatStandardDate(moment().format('YYYY-MM-DD')),
      Validators.required
    ],
    comments: '',
    statementIds: ['', Validators.required]
  })

  role: Role
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {
    super()
  }

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode
    this.role = this.authService.getRole()

    if (this.mode === 'create') {
      // When arriving from statement we already have the statementId.
      const statementId: string = this.activatedRoute.snapshot.queryParams
        .statementId
      if (statementId) {
        this.initialSearchResults = {
          statementIds: [statementId]
        }
        this.form.get('statementIds').setValue([statementId])
      }

      // Suggested next invoice number with API service
      this.resourceService
        .show('invoices', 'suggest-next-number')
        .subscribe((suggestionRes: { suggestedNumber: string }) => {
          this.form.controls.number.setValue(suggestionRes.suggestedNumber)
        })

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/invoices',
          label: 'Factures'
        },
        {
          label: 'Nouvelle facture'
        }
      ])
    } else {
      // Edit mode.
      this.invoice = await this.resourceService
        .show('invoices', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Invoice) => res)

      this.form.controls.number.setValue(this.invoice.number)
      this.form.controls.comments.setValue(this.invoice.comments)
      this.form.controls.editionDate.setValue(
        this.formatStandardDate(
          moment(this.invoice.editionDate).format('YYYY-MM-DD')
        )
      )
      this.form.controls.statementIds.setValue(this.invoice.statement.id)

      this.initialSearchResults = {
        statementIds: [this.invoice.statement.id.toString()]
      }

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/invoices',
          label: 'Factures'
        },
        {
          label: this.invoice.number
        },
        { label: 'Editer' }
      ])
    }
  }
  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('number', this.form.value.number)
    formData.append('statementId', this.form.value.statementIds)
    formData.append('comments', this.form.value.comments)
    formData.append(
      'editionDate',
      this.formatMyDatePickerDate(this.form.value.editionDate)
    )

    if (this.mode === 'create') {
      this.resourceService.store('invoices', formData).subscribe(
        (createdInvoice: Invoice) => {
          this.loading = false
          this.flashMessagesService.show(`La facture a bien été enregistrée`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/statements', createdInvoice.statement.id], {
            queryParams: { directOpen: 'addReceipt' }
          })
        },
        (err: HttpErrorResponse) => {
          this.loading = false
          this.flashMessagesService.show(
            err.status === 409
              ? this.conflictErrorMessage
              : 'Error ' + JSON.stringify(err.error.message),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('invoices', this.invoice.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(
              `La facture a bien été mise à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )

            this.router.navigate(['/statements', this.invoice.statement.id], {
              queryParams: { directOpen: 'addReceipt' }
            })
          },
          (err) => {
            this.loading = false
            this.flashMessagesService.show(
              err.status === 409
                ? this.conflictErrorMessage
                : 'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.form
      .get('statementIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'statements')
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
