import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core'
import { Status } from '../../common/enums/status.enum'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnChanges {
  @Input() status: Status
  @Input() showStatusName = false
  @Input() showEditModal = false
  @Output() updateStatus: EventEmitter<Status> = new EventEmitter()
  @Output() canceled: EventEmitter<void> = new EventEmitter()

  statusNumber: number
  statusText: string

  Status = Status

  form: FormGroup = this.formBuilder.group({
    status: [null, Validators.required]
  })

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges() {
    this.form.get('status').setValue(this.status)

    // Calculate statusNumber based on status to show color scale
    this.statusNumber = Object.values(Status).indexOf(this.status) + 1

    switch (this.status) {
      case Status.PendingValidation:
        this.statusText = 'En cours de validation'
        break
      case Status.Validated:
        this.statusText = 'Validé'
        break
      case Status.Sent:
        this.statusText = 'Envoyé'
        break
      case Status.ReceiptReceived:
        this.statusText = 'FSS reçue'
        break

      default:
        break
    }
  }

  update() {
    this.updateStatus.emit(this.form.value.status as Status)
    this.showEditModal = false
  }

  cancel() {
    this.showEditModal = false
    this.canceled.emit()
  }
}
