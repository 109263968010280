import { Component, Input, OnInit } from '@angular/core'
import { Nature } from '../../../common/interfaces/nature.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-nature-table',
  templateUrl: './nature-table.component.html',
  styleUrls: ['./nature-table.component.scss']
})
export class NatureTableComponent implements OnInit {
  @Input() natures: Nature[]
  natureToDelete: Nature

  role: Role
  Role = Role

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.role = this.authService.getRole()
  }
}
