import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Type } from '../../../common/interfaces/type.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-type-create-edit',
  templateUrl: './type-create-edit.component.html',
  styleUrls: ['./type-create-edit.component.scss'],
  providers: [ResourceService]
})
export class TypeCreateEditComponent implements OnInit {
  mode: string
  type: Type
  typeToDelete: Type
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/types',
          label: `Natures de dépenses`
        },
        {
          label: 'Nouvelle nature de dépenses'
        }
      ])
    } else {
      // Edit mode.
      this.type = await this.resourceService
        .show('types', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Type) => res)

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/types',
          label: `Natures de dépenses`
        },
        {
          label: this.type.name
        },
        {
          label: 'Editer'
        }
      ])

      this.form.controls.name.setValue(this.type.name)
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)

    if (this.mode === 'create') {
      this.resourceService.store('types', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(
            `La nature de dépenses a bien été enregistrée`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          this.router.navigate(['/types'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService.update('types', this.type.id, formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(
            `La nature de dépenses a bien été mise à jour`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          this.router.navigate(['/types'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    }
  }
}
