import {
  Component,
  Input,
  ElementRef,
  EventEmitter,
  Output
} from '@angular/core'
import { Expense } from '../../../common/interfaces/expense.interface'
import { HasDropdown } from '../../../common/base-components/has-dropdown'
import { PaymentMethod } from '../../../common/enums/payment-method.enum'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: ['./expense-table.component.scss']
})
export class ExpenseTableComponent extends HasDropdown {
  @Input() expenses: Expense[]
  @Output() orderByChanged: EventEmitter<{
    orderBy: string
    orderByDesc: boolean
  }> = new EventEmitter()
  expenseToDelete: Expense
  PaymentMethod = PaymentMethod
  storagePath: string = environment.storagePath

  orderByDesc = false
  orderBy: string

  constructor(elementRef: ElementRef) {
    super(elementRef)
  }

  order(property: string) {
    if (this.orderBy === property) {
      this.orderByDesc = !this.orderByDesc
    }
    this.orderBy = property
    this.orderByChanged.emit({
      orderBy: this.orderBy,
      orderByDesc: this.orderByDesc
    })
  }
}
