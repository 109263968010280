import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { Role } from '../../../common/enums/role.enum'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Technician } from '../../../common/interfaces/technician.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-technician-list',
  templateUrl: './technician-list.component.html',
  styleUrls: ['./technician-list.component.scss']
})
export class TechnicianListComponent implements OnInit {
  paginator: Paginator<Technician>
  queryParams: object

  filterForm: FormGroup = this.formBuilder.group({
    technicianIds: [],
    page: ''
  })

  role: Role
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: `Intervenants`
      }
    ])
  }

  ngOnInit() {
    this.role = this.authService.getRole()
    this.activatedRoute.queryParams.subscribe(async queryParams => {
      this.queryParams = queryParams
      this.paginator = await this.resourceService
        .list('technicians', queryParams)
        .toPromise()
        .then((res: Paginator<Technician>) => res)
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      this.router.navigate([], { queryParams: formValues })
    })
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('technicianIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'technicians')
          .map((s: SearchResult) => s.id.toString())
      )
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
