import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core'
import { Invoice } from '../../../common/interfaces/invoice.interface'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-invoice-payment-modal',
  templateUrl: './invoice-payment-modal.component.html',
  styleUrls: ['./invoice-payment-modal.component.scss'],
  providers: [ResourceService]
})
export class InvoicePaymentModalComponent extends HasDatepicker
  implements OnChanges {
  @Input() invoice: Invoice
  @Output() invoiceUpdated: EventEmitter<Invoice> = new EventEmitter()
  @Output() actionCanceled: EventEmitter<boolean> = new EventEmitter()

  form: FormGroup = this.formBuilder.group({
    paymentDate: [null, Validators.required]
  })

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private flashMessagesService: FlashMessagesService
  ) {
    super()
  }

  ngOnChanges() {
    // If we have already a paymentDate, default action is to remove it. If we don't, the default date is today.
    this.form
      .get('paymentDate')
      .setValue(this.invoice.paymentDate ? null : this.formatStandardDate())
  }

  cancel() {
    this.actionCanceled.emit()
  }

  submit() {
    const formData = new FormData()

    if (this.form.value.paymentDate) {
      formData.append(
        'paymentDate',
        this.formatMyDatePickerDate(this.form.value.paymentDate)
      )
    }

    this.resourceService
      .patch('invoices', this.invoice.id, 'mark-payment-date', formData)
      .subscribe(
        (invoiceRes: Invoice) => {
          this.invoice = null

          this.flashMessagesService.show(
            `Le paiement de la facture a bien été mis à jour`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
          this.invoiceUpdated.emit(invoiceRes)
        },
        err => {
          this.flashMessagesService.show(
            'Erreur :' +
              err.error.message.map(m => Object.values(m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }
}
