import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core'
import { Market } from '../../../common/interfaces/market.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { OrderLine } from '../../../common/interfaces/order-line.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-market-table',
  templateUrl: './market-table.component.html',
  styleUrls: ['./market-table.component.scss']
})
export class MarketTableComponent implements OnChanges {
  @Input() markets: Market[]
  @Output() orderByChanged: EventEmitter<{
    orderBy: string
    orderByDesc: boolean
  }> = new EventEmitter()
  marketToDelete: Market

  role: Role
  Role = Role

  orderByDesc = false
  orderBy: string

  constructor(private authService: AuthService) {}

  ngOnChanges() {
    this.role = this.authService.getRole()
  }

  order(property: string) {
    if (this.orderBy === property) {
      this.orderByDesc = !this.orderByDesc
    }
    this.orderBy = property
    this.orderByChanged.emit({
      orderBy: this.orderBy,
      orderByDesc: this.orderByDesc
    })
  }
}
