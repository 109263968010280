import { Injectable } from '@angular/core'
import { Title, Meta } from '@angular/platform-browser'
import { MetaObject } from '../interfaces/meta-object.interface'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) {}

  defaultMeta: MetaObject = {
    title: environment.appName,
    description: `Application de gestion d'activité`,
    path: '',
    ogImage: 'assets/images/logo.png'
  }

  setTags(metaObject: MetaObject): void {
    this.title.setTitle(
      `${metaObject.title || this.defaultMeta.title} | ${environment.appName}`
    )
    this.meta.addTags([
      {
        name: 'description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:title',
        content: metaObject.title || this.defaultMeta.title
      }
    ])
  }
}
