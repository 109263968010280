import { Component, OnInit, Input } from '@angular/core'
import { Supplier } from '../../../common/interfaces/supplier.interface'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-supplier-create-edit',
  templateUrl: './supplier-create-edit.component.html',
  styleUrls: ['./supplier-create-edit.component.scss']
})
export class SupplierCreateEditComponent implements OnInit {
  mode: string
  supplier: Supplier
  supplierToDelete: Supplier
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/suppliers',
          label: 'Fournisseurs'
        },
        { label: 'Nouveau fournisseur' }
      ])
    } else {
      // Edit mode.
      this.supplier = await this.resourceService
        .show('suppliers', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Supplier) => res)

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/suppliers',
          label: 'Fournisseurs'
        },
        {
          label: this.supplier.name
        },
        { label: 'Editer' }
      ])

      this.form.controls.name.setValue(this.supplier.name)
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)

    if (this.mode === 'create') {
      this.resourceService.store('suppliers', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(
            `Le fournisseur a bien été enregistrée`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          this.router.navigate(['/suppliers'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('suppliers', this.supplier.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(
              `Le fournisseur a bien été mise à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )

            this.router.navigate(['/suppliers'])
          },
          err => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map(m => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
