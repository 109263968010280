import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'

import { ResourceService } from '../../../common/services/resource.service'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { environment } from '../../../../environments/environment'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { User } from '../../../common/interfaces/user.interface'
import { FormGroup, FormBuilder } from '@angular/forms'
import { MetaService } from '../../../common/services/meta.service'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ResourceService]
})
export class UserListComponent implements OnInit {
  paginator: Paginator<User>

  filterForm: FormGroup = this.formBuilder.group({
    page: ''
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        path: '/users',
        label: 'Collaborateurs'
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      // Create request params and set actual params (in case of reload)
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)
      params = params.set('includeNotActive', 'true')

      if (queryParams.toXLS) {
        params = params.set('toXLS', 'true')
        this.resourceService
          .list('users', params)
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/users'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        this.resourceService
          .list('users', params)
          .subscribe((userRes: Paginator<User>) => {
            this.paginator = userRes
          })
      }
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
