import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import * as moment from 'moment'

import { appConstants } from '../../../app.constants'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { PaymentMethod } from '../../../common/enums/payment-method.enum'
import { Expense } from '../../../common/interfaces/expense.interface'
import { Supplier } from '../../../common/interfaces/supplier.interface'
import { Type } from '../../../common/interfaces/type.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-expense-create-edit',
  templateUrl: './expense-create-edit.component.html',
  styleUrls: ['./expense-create-edit.component.scss']
})
export class ExpenseCreateEditComponent extends HasDatepicker
  implements OnInit {
  mode: string
  expense: Expense
  expenseToDelete: Expense
  types: Type[]
  suppliers: Supplier[]
  amountSubjectToVATWithTaxes: number
  amountWithTaxes: number
  PaymentMethod = PaymentMethod
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    editionDate: ['', Validators.required],
    dueDate: '',
    paymentDate: '',
    amountSubjectToVAT: [null, Validators.required],
    amountNotSubjectToVAT: [0, Validators.required],
    taxes: [null, Validators.required],
    paymentMethod: [null, Validators.required],
    reference: '',
    comments: '',
    attachment: null,
    removeCurrentAttachment: null,
    typeId: [null, Validators.required],
    supplierId: [null, Validators.required]
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {
    super()
  }

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    this.resourceService
      .list('types', { withoutPagination: 'true' })
      .subscribe((res: Type[]) => {
        this.types = res
      })

    this.resourceService
      .list('suppliers', { withoutPagination: 'true' })
      .subscribe((res: Supplier[]) => {
        this.suppliers = res
      })

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/expenses',
          label: 'Dépenses'
        },
        { label: 'Nouvelle dépense' }
      ])
    } else {
      // Edit mode.
      this.expense = await this.resourceService
        .show('expenses', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Expense) => res)

      this.form.controls.editionDate.setValue(
        this.formatStandardDate(
          moment(this.expense.editionDate).format('YYYY-MM-DD')
        )
      )

      this.form.controls.amountSubjectToVAT.setValue(
        this.expense.amountSubjectToVAT
      )
      this.form.controls.amountNotSubjectToVAT.setValue(
        this.expense.amountNotSubjectToVAT
      )
      this.form.controls.taxes.setValue(this.expense.taxes)
      this.form.controls.paymentMethod.setValue(this.expense.paymentMethod)
      this.form.controls.reference.setValue(this.expense.reference)
      this.form.controls.comments.setValue(this.expense.comments)
      this.form.controls.typeId.setValue(this.expense.type.id)
      this.form.controls.supplierId.setValue(this.expense.supplier.id)

      if (this.expense.dueDate) {
        this.form.controls.dueDate.setValue(
          this.formatStandardDate(
            moment(this.expense.dueDate).format('YYYY-MM-DD')
          )
        )
      }

      if (this.expense.paymentDate) {
        this.form.controls.paymentDate.setValue(
          this.formatStandardDate(
            moment(this.expense.paymentDate).format('YYYY-MM-DD')
          )
        )
      }

      this.calculateAmounts(
        this.expense.amountSubjectToVAT,
        this.expense.amountNotSubjectToVAT,
        this.expense.taxes
      )

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/expenses',
          label: 'Dépenses'
        },
        {
          label: this.expense.type.name
        },
        {
          label: 'Editer'
        }
      ])
    }

    this.form.valueChanges.subscribe(
      (formValues: {
        taxes: number
        amountSubjectToVAT: number
        amountNotSubjectToVAT: number
      }) => {
        if (
          formValues.amountSubjectToVAT ||
          formValues.amountNotSubjectToVAT ||
          formValues.taxes
        ) {
          this.calculateAmounts(
            formValues.amountSubjectToVAT,
            formValues.amountNotSubjectToVAT,
            formValues.taxes
          )
        }
      }
    )
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()

    formData.append(
      'editionDate',
      this.formatMyDatePickerDate(this.form.value.editionDate)
    )
    formData.append('amountSubjectToVAT', this.form.value.amountSubjectToVAT)
    formData.append(
      'amountNotSubjectToVAT',
      this.form.value.amountNotSubjectToVAT
    )
    formData.append('taxes', this.form.value.taxes)
    formData.append('paymentMethod', this.form.value.paymentMethod)
    formData.append('typeId', this.form.value.typeId)
    formData.append('supplierId', this.form.value.supplierId)
    formData.append(
      'dueDate',
      this.formatMyDatePickerDate(this.form.value.dueDate)
    )
    formData.append(
      'paymentDate',
      this.formatMyDatePickerDate(this.form.value.paymentDate)
    )
    formData.append('reference', this.form.value.reference)
    formData.append('comments', this.form.value.comments)

    if (this.form.value.attachment) {
      formData.append(
        'attachment',
        this.form.value.attachment.content,
        this.form.value.attachment.name
      )
    } else if (this.form.value.removeCurrentAttachment) {
      formData.append('removeCurrentAttachment', 'true')
    }

    if (this.mode === 'create') {
      this.resourceService.store('expenses', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(`La dépense a bien été enregistrée`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/expenses'])
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' + JSON.stringify(err.error.message),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('expenses', this.expense.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(
              `La dépense a bien été mise à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )

            this.router.navigate(['/expenses'])
          },
          err => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  // Set calculated amounts variables to display on UI.
  calculateAmounts(
    amountSubjectToVAT: number,
    amountNotSubjectToVAT: number,
    taxes: number
  ) {
    this.amountSubjectToVATWithTaxes =
      Math.round((amountSubjectToVAT + (taxes || 0)) * 100) / 100
    this.amountWithTaxes =
      this.amountSubjectToVATWithTaxes + (amountNotSubjectToVAT || 0)
  }
}
