import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  Renderer2,
  ViewChild,
  ElementRef
} from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { Invoice } from '../../../common/interfaces/invoice.interface'

@Component({
  selector: 'app-receipt-create-modal',
  templateUrl: './receipt-create-modal.component.html',
  styleUrls: ['./receipt-create-modal.component.scss']
})
export class ReceiptCreateModalComponent implements OnInit, OnChanges {
  @ViewChild('name') nameInputEl: ElementRef
  @Input() invoice: Invoice
  @Output() createReceipt: EventEmitter<{
    name: string
    amount: number
    createAnotherOne: boolean
  }> = new EventEmitter()
  @Output() createCanceled: EventEmitter<void> = new EventEmitter()

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    amount: ['', Validators.required],
    invoiceId: ['', Validators.required]
  })
  showModal: boolean

  constructor(private formBuilder: FormBuilder, private renderer: Renderer2) {}

  ngOnInit() {
    this.form.get('invoiceId').setValue(this.invoice.id)
  }

  ngOnChanges() {
    this.showModal = true
    this.renderer.addClass(document.querySelector('html'), 'is-clipped')
  }

  send(createAnotherOne = false) {
    this.createReceipt.emit({
      name: this.form.value.name,
      amount: this.form.value.amount,
      createAnotherOne: !!createAnotherOne
    })

    if (createAnotherOne) {
      this.form.reset()
      this.form.get('invoiceId').setValue(this.invoice.id)
      this.nameInputEl.nativeElement.focus()
    } else {
      this.closeModal()
    }
  }

  cancel() {
    this.createCanceled.emit()
    this.closeModal()
  }

  closeModal() {
    this.showModal = false
    this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
  }
}
