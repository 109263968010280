import { Component, OnInit, OnChanges } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { Type } from '../../../common/interfaces/type.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder } from '@angular/forms'

@Component({
  selector: 'app-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.scss'],
  providers: [ResourceService]
})
export class TypeListComponent implements OnInit {
  paginator: Paginator<Type>

  filterForm: FormGroup = this.formBuilder.group({
    page: ''
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: `Natures de dépenses`
      }
    ])
  }

  ngOnInit() {
    // Subscribe to queryParams to reload results if change in queryParams (delete from modal).
    this.activatedRoute.queryParams.subscribe(async queryParams => {
      delete this.paginator
      this.paginator = await this.resourceService
        .list('types', queryParams)
        .toPromise()
        .then((res: Paginator<Type>) => res)
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      this.router.navigate([], {
        queryParams: formValues
      })
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
