import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { User } from '../../../common/interfaces/user.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FlashMessagesService } from 'angular2-flash-messages'
import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { HTMLInputEvent } from '../../../common/interfaces/html-input-event.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { AuthService } from '../../../common/services/auth.service'
import { Role } from '../../../common/enums/role.enum'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-user-create-edit',
  templateUrl: './user-create-edit.component.html',
  styleUrls: ['./user-create-edit.component.scss'],
  providers: [ResourceService]
})
export class UserCreateEditComponent implements OnInit {
  @ViewChild('imageInput') imageInputEl: ElementRef

  user: User
  userToDelete: User
  mode: string

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: '',
    role: [null, [Validators.required]],
    hourlyRate: '',
    minTurnover: '',
    objective: '',
    image: [null]
  })

  image: { name: string; content: any }
  storagePath: string = environment.storagePath
  currentUser: User
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    this.currentUser = await this.authService
      .me()
      .toPromise()
      .then((res) => res)

    if (this.mode === 'create') {
      // Password required only on create mode.
      this.form.get('password').setValidators(Validators.required)
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/users',
          label: 'Utilisateurs'
        },
        {
          label: 'Nouvel utilisateur'
        }
      ])
    } else {
      if (this.currentUser.role !== Role.Admin) {
        this.user = await this.authService
          .me()
          .toPromise()
          .then((res) => res)
      } else {
        this.user = await this.resourceService
          .show('users', this.activatedRoute.snapshot.params.id)
          .toPromise()
          .then((res) => res)
      }

      this.form.controls.name.setValue(this.user.name)
      this.form.controls.email.setValue(this.user.email)
      this.form.controls.password.setValue(this.user.password)
      this.form.controls.role.setValue(this.user.role)

      // Set breadcrumbs
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/users',
          label: 'Utilisateurs'
        },
        {
          path: '/users/' + this.user.id,
          label: this.user.name
        },
        {
          label: 'Editer'
        }
      ])
    }
  }

  submit(form: FormGroup) {
    const formData = new FormData()
    formData.append('name', form.value.name)
    formData.append('email', form.value.email)
    formData.append('role', form.value.role)

    if (form.value.password) {
      formData.append('password', form.value.password)
    }

    if (this.image) {
      formData.append('image', this.image.content, this.image.name)
    }

    if (this.mode === 'create') {
      this.resourceService.store('users', formData).subscribe(
        (userRes: User) => {
          this.flashMessagesService.show(`L'utilisateur a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          if (this.currentUser.role === Role.Admin) {
            this.router.navigate(['/users', userRes.id])
          } else {
            this.router.navigate(['/'])
          }
        },
        (err) => {
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource (only Admins can update another user than the current one).
      const observable: Observable<any> =
        this.currentUser.role === Role.Admin
          ? this.resourceService.update('users', this.user.id, formData)
          : this.resourceService.patch('users', 'update-myself', '', formData)

      observable.subscribe(
        (res) => {
          if (
            this.currentUser.id === this.user.id &&
            form.value.email !== this.currentUser.email
          ) {
            this.flashMessagesService.show(
              `Vous avez bien mis à jour vos identifiants. Votre email a été modifié, veuillez vous re-connecter pour relancer la session.`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
            this.router.navigate(['/logout'])
          } else {
            this.flashMessagesService.show(
              `L'utilisateur a bien été mis à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
            if (this.currentUser.role === Role.Admin) {
              this.router.navigate(['/users', this.user.id])
            } else {
              this.router.navigate(['/'])
            }
          }
        },
        (err) => {
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    }
  }

  // Triggers on adding image
  imageInputEvent(fileInput: HTMLInputEvent) {
    this.image = {
      name: fileInput.target.files[0].name,
      content: this.imageInputEl.nativeElement.files.item(0)
    }
  }

  delete(user: User) {
    this.userToDelete = user
  }
  onDeleteCanceled() {
    delete this.userToDelete
  }
}
