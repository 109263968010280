import { Component, OnInit } from '@angular/core'
import { Agency } from '../../../common/interfaces/agency.interface'
import { RegionalDirectorate } from '../../../common/interfaces/regional-directorate.interface'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'
import { AddressObject } from '../../../common/interfaces/address-object.interface'

@Component({
  selector: 'app-agency-create-edit',
  templateUrl: './agency-create-edit.component.html',
  styleUrls: ['./agency-create-edit.component.scss']
})
export class AgencyCreateEditComponent implements OnInit {
  mode: string
  agency: Agency
  agencyToDelete: Agency
  regionalDirectorates: RegionalDirectorate[]
  loading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    billingName: ['', Validators.required],
    paymentPeriod: ['', Validators.required],
    addressName: ['', Validators.required],
    streetNumber: '',
    route: '',
    locality: '',
    postalCode: '',
    country: '',
    billingAddress: ['', Validators.required],
    regionalDirectorateId: [null, Validators.required]
  })
  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    this.resourceService
      .list('regional-directorates', { withoutPagination: 'true' })
      .subscribe((res: RegionalDirectorate[]) => {
        this.regionalDirectorates = res
      })

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/agencies',
          label: 'Agences'
        },
        { label: 'Nouvelle agence' }
      ])
    } else {
      // Edit mode.
      this.agency = await this.resourceService
        .show('agencies', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Agency) => res)

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/agencies',
          label: 'Agences'
        },
        {
          path: '/agencies/' + this.agency.id,
          label: this.agency.name
        },
        { label: 'Editer' }
      ])

      this.form.controls.name.setValue(this.agency.name)
      this.form.controls.billingName.setValue(this.agency.billingName)
      this.form.controls.paymentPeriod.setValue(this.agency.paymentPeriod)
      this.form.controls.addressName.setValue(this.agency.addressName)
      this.form.controls.streetNumber.setValue(this.agency.streetNumber)
      this.form.controls.route.setValue(this.agency.route)
      this.form.controls.locality.setValue(this.agency.locality)
      this.form.controls.postalCode.setValue(this.agency.postalCode)
      this.form.controls.country.setValue(this.agency.country)
      this.form.controls.billingAddress.setValue(this.agency.billingAddress)
      this.form.controls.regionalDirectorateId.setValue(
        this.agency.regionalDirectorate.id
      )
    }
  }

  submit(): void {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('billingName', this.form.value.billingName)
    formData.append('paymentPeriod', this.form.value.paymentPeriod)
    formData.append(
      'regionalDirectorateId',
      this.form.value.regionalDirectorateId
    )
    formData.append('billingAddress', this.form.value.billingAddress)

    if (this.form.value.addressName) {
      formData.append('addressName', this.form.value.addressName)
      formData.append('streetNumber', this.form.value.streetNumber || '')
      formData.append('route', this.form.value.route || '')
      formData.append('locality', this.form.value.locality || '')
      formData.append('postalCode', this.form.value.postalCode || '')
      formData.append('country', this.form.value.country || '')
    }

    if (this.mode === 'create') {
      this.resourceService.store('agencies', formData).subscribe(
        () => {
          this.loading = false
          this.flashMessagesService.show(`L'agence a bien été enregistrée`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/agencies'])
        },
        (err) => {
          this.loading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('agencies', this.agency.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(`L'agence a bien été mise à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })

            this.router.navigate(['/agencies', this.agency.id])
          },
          (err) => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map((m) => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  onUpdateFormAddress(addressObject: AddressObject, prefix?: string): void {
    this.form.controls[
      prefix ? prefix + 'AddressName' : 'addressName'
    ].setValue(addressObject.addressName)
    this.form.controls[
      prefix ? prefix + 'StreetNumber' : 'streetNumber'
    ].setValue(addressObject.streetNumber)
    this.form.controls[prefix ? prefix + 'Route' : 'route'].setValue(
      addressObject.route
    )
    this.form.controls[prefix ? prefix + 'Locality' : 'locality'].setValue(
      addressObject.locality
    )
    this.form.controls[prefix ? prefix + 'PostalCode' : 'postalCode'].setValue(
      addressObject.postalCode
    )
    this.form.controls[prefix ? prefix + 'Country' : 'country'].setValue(
      addressObject.country
    )
  }

  setBillingAddress(address: string) {
    this.form.get('billingAddress').setValue(address)
  }
}
