import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { Status } from '../../../common/enums/status.enum'
import { Nature } from '../../../common/interfaces/nature.interface'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { IMyDate } from 'mydatepicker'

@Component({
  selector: 'app-statement-list',
  templateUrl: './statement-list.component.html',
  styleUrls: ['./statement-list.component.scss'],
  providers: [ResourceService]
})
export class StatementListComponent extends HasDatepicker implements OnInit {
  paginator: Paginator<Statement>
  queryParams: object
  Status = Status
  natures: Nature[]

  totalAmounts: number
  lastFormValues: any

  role: string
  Role = Role
  loading = false

  filterForm: FormGroup = this.formBuilder.group({
    statementIds: [],
    referentIds: [],
    agencyIds: [],
    marketIds: [],
    userIds: [],
    natureIds: [],
    status: null,
    invoiceStatus: '',
    receiptStatus: '',
    natureId: null,
    startDateFrom: '',
    startDateTo: '',
    orderBy: null,
    orderByDesc: null,
    page: '',
    type: ''
  })

  dates: {
    startDateFrom: { date: IMyDate }
    startDateTo: { date: IMyDate }
  } = {
    startDateFrom: { date: null },
    startDateTo: { date: null }
  }

  constructor(
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    super()
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Relevés'
      }
    ])
  }

  async ngOnInit() {
    this.role = this.authService.getRole()

    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      this.setDateObjects(queryParams)

      this.queryParams = queryParams

      if (queryParams.toXLS) {
        this.resourceService
          .list('statements', { ...queryParams, toXLS: true })
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/statements'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        this.filterForm.patchValue(queryParams)
        this.loading = true
        this.paginator = (await this.resourceService
          .list('statements', queryParams)
          .toPromise()
          .then((res: Paginator<Statement>) => res)
          .catch((err) => {
            this.loading = false
          })) as Paginator<Statement>

        this.loading = false
        // Calculate total amounts of selected statement's orderLines
        this.totalAmounts = await this.resourceService
          .list('statements', { ...queryParams, calculateTotal: true })
          .toPromise()
          .then((res: { total: number }) => res.total)
      }
    })

    this.filterForm.valueChanges.subscribe(async (formValues) => {
      // Return to page 1 if we changed our filters (changes in form that are not only the page).
      if (
        this.lastFormValues &&
        formValues !== this.lastFormValues &&
        formValues.page === this.lastFormValues.page
      ) {
        formValues.page = 1
      }
      this.lastFormValues = formValues
      this.router.navigate([], { queryParams: formValues })
    })

    this.natures = await this.resourceService
      .list('natures', { withoutPagination: 'true' })
      .toPromise()
      .then((res) => res)
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.filterForm.patchValue({
      statementIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'statements')
        .map((s: SearchResult) => s.id.toString()),
      marketIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'markets')
        .map((s: SearchResult) => s.id.toString()),
      userIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'users')
        .map((s: SearchResult) => s.id.toString()),
      agencyIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'agencies')
        .map((s: SearchResult) => s.id.toString()),
      natureIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'natures')
        .map((s: SearchResult) => s.id.toString()),
      referentIds: searchResults
        .filter((s: SearchResult) => s.resourceName === 'referents')
        .map((s: SearchResult) => s.id.toString())
    })
  }

  onOrderByChanged({
    orderBy,
    orderByDesc
  }: {
    orderBy: string
    orderByDesc: boolean
  }) {
    this.filterForm.patchValue({
      orderBy,
      orderByDesc: orderByDesc ? 'true' : null
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
