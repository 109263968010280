import { CurrencyPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ChartData,
  ChartDataSets,
  ChartOptions,
  ChartTooltipItem
} from 'chart.js'
import * as moment from 'moment'

import { environment } from '../../environments/environment'
import { appConstants } from '../app.constants'
import { ChartItem } from '../common/interfaces/chart-item.interface'
import { BreadcrumbService } from '../common/services/breadcrumb.service'
import { ResourceService } from '../common/services/resource.service'

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  providers: [ResourceService]
})
export class MonitoringComponent implements OnInit {
  // Filters
  filterForm: FormGroup = this.formBuilder.group({
    monthFrom: '',
    yearFrom: '',
    monthTo: '',
    yearTo: ''
  })
  months: string[]
  years: number[]
  loadingExportExpenses: boolean
  loadingExportTurnover: boolean

  // Pie chart and list.
  types: ChartItem[]
  totalTypeAmounts: number
  pieChartData: number[]
  pieChartLabels: string[]
  pieChartOptions: ChartOptions = {
    legend: { display: false },
    tooltips: {
      callbacks: {
        // Custom tooltip to display number in currency format.
        label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
          return `${
            this.pieChartLabels[tooltipItem.index]
          }: ${this.currencyPipe.transform(
            data.datasets[0].data[tooltipItem.index],
            'EUR'
          )} HT soumis à TVA`
        }
      }
    },
    responsive: true
  }
  pieColors = [
    '#AEF8BC',
    '#5FD88E',
    '#23A36F',
    '#0F6E5A',
    '#E0FCD9',
    '#F3F869',
    '#DFEA09',
    '#9EA804',
    '#7F8702',
    '#D9F6FE',
    '#8CED8B',
    '#6ADB75',
    '#2DA84F',
    '#13713F',
    '#FCFDCD',
    '#8CD9FE',
    '#41ABFC',
    '#2064B5',
    '#0C3178'
  ]

  // Bar charts' options.
  barChartOptions: ChartOptions = {
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
          const dataset: ChartDataSets = data.datasets[tooltipItem.datasetIndex]
          const value: number = data.datasets[tooltipItem.datasetIndex].data[
            tooltipItem.index
          ] as number

          return `${dataset.label}: ${this.currencyPipe.transform(
            value,
            'EUR'
          )}`
        }
      }
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: { display: false },
          ticks: {
            callback: (value) => this.formatMonthDate(value)
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: { display: false },
          ticks: {
            callback: (value) => this.currencyPipe.transform(value, 'EUR'),
            fontColor: '#b0bac9'
          }
        }
      ]
    }
  }
  barChartLabels: string[] = []

  // Estimation turnover bar chart and list.
  estimationBarChartDataSets: ChartDataSets[]
  estimationExpenseData: ChartItem[] = []
  estimationInvoiceData: ChartItem[] = []
  totalEstimationExpenseData: number
  totalEstimationInvoiceData: number

  // Real turnover bar chart and list.
  realBarChartDataSets: ChartDataSets[]
  realExpenseData: ChartItem[] = []
  realInvoiceData: ChartItem[] = []
  totalRealExpenseData: number
  totalRealInvoiceData: number

  // Other
  queryParams: object
  formatMonthDate = (value) => moment(value, 'MM-YY').format('MMM YY')

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Reporting'
      }
    ])
    moment.locale('fr')
  }

  ngOnInit() {
    // Get 10 last years and 5 next years in an array.
    const currentYear: number = parseInt(
      moment().subtract(10, 'years').format('YYYY'),
      10
    )
    this.years = []
    for (let i = 0; i < 15; i++) {
      this.years.push(currentYear + i)
    }
    this.months = moment.months()

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams
      const dateFrom = moment(queryParams.dateFrom, 'YYYY-MM-DD')
      const dateTo = moment(queryParams.dateTo, 'YYYY-MM-DD')

      // We update form inputs.
      this.filterForm.setValue({
        monthFrom: dateFrom.format('MMMM'),
        yearFrom: dateFrom.format('YYYY'),
        monthTo: dateTo.format('MMMM'),
        yearTo: dateTo.format('YYYY')
      })

      this.barChartLabels = this.getRangeMonths(dateFrom, dateTo)
      this.buildCharts(queryParams.dateFrom, queryParams.dateTo)
    })

    this.filterForm.valueChanges.subscribe(async (formValues) => {
      // Reset data to prevent console errors on ngFor lists while refreshing.
      this.estimationExpenseData = []
      this.estimationInvoiceData = []
      this.realExpenseData = []
      this.realInvoiceData = []

      this.router.navigate([], {
        queryParams: {
          dateFrom: moment(
            `${formValues.monthFrom} ${formValues.yearFrom}`,
            'MMMM YYYY'
          )
            .startOf('month')
            .format('YYYY-MM-DD'),
          dateTo: moment(
            `${formValues.monthTo} ${formValues.yearTo}`,
            'MMMM YYYY'
          )
            .endOf('month')
            .format('YYYY-MM-DD')
        },
        queryParamsHandling: 'merge'
      })
    })
  }

  // Build 3 charts.
  buildCharts(dateFrom: moment.Moment, dateTo: moment.Moment): void {
    // Expenses by type pie chart.
    this.resourceService
      .list('expenses', {
        groupBy: 'type',
        paymentDateFrom: dateFrom,
        paymentDateTo: dateTo
      })
      .subscribe((res: ChartItem[]) => {
        this.types = res.sort((a, b) => {
          if (a.amount > b.amount) {
            return -1
          }
          if (a.amount < b.amount) {
            return 1
          }
          return 0
        })

        this.pieChartData = this.types.map(
          (t) => Math.round(t.amount * 100) / 100
        )
        this.pieChartLabels = this.types.map((t) => t.name)

        this.totalTypeAmounts = this.types.reduce(
          (prev: number, curr: ChartItem) => prev + curr.amount,
          0
        )
      })

    // Estimation Turnover Bar Chart.
    const estimationBarChartPromises: Promise<ChartItem[]>[] = [
      this.resourceService
        .list('expenses', {
          groupBy: 'monthDueDate',
          dueDateFrom: dateFrom,
          dueDateTo: dateTo
        })
        .toPromise()
        .then((expenseRes: ChartItem[]) => expenseRes),
      this.resourceService
        .list('invoices', {
          groupBy: 'monthPaymentDeadlineDate',
          paymentDeadlineDateFrom: dateFrom,
          paymentDeadlineDateTo: dateTo
        })
        .toPromise()
        .then((invoiceRes: ChartItem[]) => invoiceRes)
    ]
    Promise.all(estimationBarChartPromises).then(
      ([expenseRes, invoiceRes]: ChartItem[][]) => {
        // Get full list of months and values for selected date range.
        this.estimationExpenseData = this.fillUpEmptyMonths(expenseRes)
        this.estimationInvoiceData = this.fillUpEmptyMonths(invoiceRes)

        this.totalEstimationExpenseData = this.getTotal(
          this.estimationExpenseData
        )
        this.totalEstimationInvoiceData = this.getTotal(
          this.estimationInvoiceData
        )

        this.estimationBarChartDataSets = [
          {
            stack: 'invoices',
            data: this.estimationInvoiceData.map((cI) => cI.amount),
            label: 'Revenus prévisionels HT soumis à TVA',
            backgroundColor: appConstants.COLOR_SUCCESS
          },
          {
            stack: 'invoices',
            data: this.estimationInvoiceData.map((cI) => cI.amount * 0.2),
            label: 'Revenus prévisionels TVA',
            backgroundColor: appConstants.COLOR_SUCCESS_LIGHT
          },
          {
            stack: 'expenses',
            data: this.estimationExpenseData.map((cI) => cI.amount),
            label: 'Dépenses prévisionelles HT soumis à TVA',
            backgroundColor: appConstants.COLOR_INFO
          },
          {
            stack: 'expenses',
            data: this.estimationExpenseData.map((cI) => cI.amount * 0.2),
            label: 'Dépenses prévisionelles TVA',
            backgroundColor: appConstants.COLOR_INFO_LIGHT
          }
        ]
      }
    )

    // Real Turnover Bar Chart.
    const realBarChartPromises: Promise<ChartItem[]>[] = [
      this.resourceService
        .list('expenses', {
          groupBy: 'monthPaymentDate',
          paymentDateFrom: dateFrom,
          paymentDateTo: dateTo
        })
        .toPromise()
        .then((expenseRes: ChartItem[]) => expenseRes),
      this.resourceService
        .list('invoices', {
          groupBy: 'monthPaymentDate',
          paymentDateFrom: dateFrom,
          paymentDateTo: dateTo
        })
        .toPromise()
        .then((invoiceRes: ChartItem[]) => invoiceRes)
    ]
    Promise.all(realBarChartPromises).then(
      ([expenseRes, invoiceRes]: ChartItem[][]) => {
        // Get full list of months and values for selected date range.
        this.realExpenseData = this.fillUpEmptyMonths(expenseRes)
        this.realInvoiceData = this.fillUpEmptyMonths(invoiceRes)

        this.totalRealExpenseData = this.getTotal(this.realExpenseData)
        this.totalRealInvoiceData = this.getTotal(this.realInvoiceData)

        this.realBarChartDataSets = [
          {
            stack: 'invoices',
            data: this.realInvoiceData.map((cI) => cI.amount),
            label: 'Revenus réels HT soumis à TVA',
            backgroundColor: appConstants.COLOR_SUCCESS
          },
          {
            stack: 'invoices',
            data: this.realInvoiceData.map((cI) => cI.amount * 0.2),
            label: 'Revenus réels TVA',
            backgroundColor: appConstants.COLOR_SUCCESS_LIGHT
          },
          {
            stack: 'expenses',
            data: this.realExpenseData.map((cI) => cI.amount),
            label: 'Dépenses réelles HT soumis à TVA',
            backgroundColor: appConstants.COLOR_INFO
          },
          {
            stack: 'expenses',
            data: this.realExpenseData.map((cI) => cI.amount * 0.2),
            label: 'Dépenses réelles TVA',
            backgroundColor: appConstants.COLOR_INFO_LIGHT
          }
        ]
      }
    )
  }

  exportExpenses() {
    this.loadingExportExpenses = true
    this.resourceService
      .list('monitoring/export-expenses', this.queryParams)
      .subscribe((res: { filePath: string }) => {
        window.open(environment.storagePath + res.filePath)
        this.loadingExportExpenses = false
      })
  }

  exportTurnover() {
    this.loadingExportTurnover = true
    this.resourceService
      .list('monitoring/export-turnover', this.queryParams)
      .subscribe((res: { filePath: string }) => {
        window.open(environment.storagePath + res.filePath)
        this.loadingExportTurnover = false
      })
  }

  // Returns an array of all the months between 2 dates.
  getRangeMonths(dateFrom: moment.Moment, dateTo: moment.Moment): string[] {
    const rangeMonths = []
    while (dateFrom.isBefore(dateTo)) {
      rangeMonths.push(dateFrom.format('MM-YY'))
      dateFrom.add(1, 'month')
    }
    return rangeMonths
  }

  // As API returns items grouped by month, there is no item if no amount, even if month within selected dates.
  // This function returns the array with extra "zero amount" items if there is no value for a month.
  fillUpEmptyMonths(itemsPerMonth: ChartItem[]): ChartItem[] {
    return this.barChartLabels.map((month: string) => {
      const item: ChartItem = itemsPerMonth.find(
        (cI: ChartItem) => cI.name === month
      )

      return item ? item : { name: month, amount: 0 }
    })
  }

  getTotal(itemsPerMonth: ChartItem[]): number {
    return itemsPerMonth.reduce(
      (prev: number, curr: ChartItem) => prev + curr.amount,
      0
    )
  }
}
