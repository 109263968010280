import { CurrencyPipe, registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { JwtModule } from '@auth0/angular-jwt'
import bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { AngularGooglePlaceModule } from 'angular-google-place'
import { FlashMessagesModule } from 'angular2-flash-messages'
import { MyDatePickerModule } from 'mydatepicker'
import { ChartsModule } from 'ng2-charts'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StripHtmlPipe } from './common/pipes/strip-html.pipe'
import { AuthService } from './common/services/auth.service'
import { BreadcrumbService } from './common/services/breadcrumb.service'
import { EventService } from './common/services/event.service'
import { ViewportService } from './common/services/viewport.service'
import { MonitoringComponent } from './monitoring/monitoring.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { Error404Component } from './pages/error404/error404.component'
import { HomeComponent } from './pages/home/home.component'
import { AddressComponent } from './partials/address/address.component'
import { BreadcrumbsComponent } from './partials/breadcrumbs/breadcrumbs.component'
import { ConfirmDeleteModalComponent } from './partials/confirm-delete-modal/confirm-delete-modal.component'
import { FileInputComponent } from './partials/inputs/file-input/file-input.component'
import { GooglePlacesInputComponent } from './partials/inputs/google-places-input/google-places-input.component'
import { MultiSearchComponent } from './partials/inputs/multi-search/multi-search.component'
import { RichTextInputComponent } from './partials/inputs/rich-text-input/rich-text-input.component'
import { MetaComponent } from './partials/meta/meta.component'
import { PaginationComponent } from './partials/pagination/pagination.component'
import { SideMenuComponent } from './partials/side-menu/side-menu.component'
import { StatusBarComponent } from './partials/status-bar/status-bar.component'
import { TopMenuComponent } from './partials/top-menu/top-menu.component'
import { AgencyCreateEditComponent } from './resources/agency/agency-create-edit/agency-create-edit.component'
import { AgencyDetailComponent } from './resources/agency/agency-detail/agency-detail.component'
import { AgencyListComponent } from './resources/agency/agency-list/agency-list.component'
import { AgencyTableComponent } from './resources/agency/agency-table/agency-table.component'
import { ExpenseCreateEditComponent } from './resources/expense/expense-create-edit/expense-create-edit.component'
import { ExpenseListComponent } from './resources/expense/expense-list/expense-list.component'
import { ExpenseTableComponent } from './resources/expense/expense-table/expense-table.component'
import { InvoiceCreateEditComponent } from './resources/invoice/invoice-create-edit/invoice-create-edit.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { InvoicePaymentModalComponent } from './resources/invoice/invoice-payment-modal/invoice-payment-modal.component'
import { InvoiceTableComponent } from './resources/invoice/invoice-table/invoice-table.component'
import { MarketCreateEditComponent } from './resources/market/market-create-edit/market-create-edit.component'
import { MarketListComponent } from './resources/market/market-list/market-list.component'
import { MarketTableComponent } from './resources/market/market-table/market-table.component'
import { NatureCreateEditComponent } from './resources/nature/nature-create-edit/nature-create-edit.component'
import { NatureListComponent } from './resources/nature/nature-list/nature-list.component'
import { NatureTableComponent } from './resources/nature/nature-table/nature-table.component'
import { CategoryTableComponent } from './resources/order-line/category-table/category-table.component'
import { OrderLineTableComponent } from './resources/order-line/order-line-table/order-line-table.component'
import { ReceiptCreateModalComponent } from './resources/receipt/receipt-create-modal/receipt-create-modal.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentCreateFormComponent } from './resources/referent/referent-create-form/referent-create-form.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { ReferentTableComponent } from './resources/referent/referent-table/referent-table.component'
import { RegionalDirectorateCreateEditComponent } from './resources/regional-directorate/regional-directorate-create-edit/regional-directorate-create-edit.component'
import { RegionalDirectorateListComponent } from './resources/regional-directorate/regional-directorate-list/regional-directorate-list.component'
import { RegionalDirectorateTableComponent } from './resources/regional-directorate/regional-directorate-table/regional-directorate-table.component'
import { DateEditModalComponent } from './resources/statement/date-edit-modal/date-edit-modal.component'
import { ReminderCreateModalComponent } from './resources/statement/reminder-create-modal/reminder-create-modal.component'
import { StatementCreateEditComponent } from './resources/statement/statement-create-edit/statement-create-edit.component'
import { StatementDetailComponent } from './resources/statement/statement-detail/statement-detail.component'
import { StatementListComponent } from './resources/statement/statement-list/statement-list.component'
import { StatementTableComponent } from './resources/statement/statement-table/statement-table.component'
import { SupplierCreateEditComponent } from './resources/supplier/supplier-create-edit/supplier-create-edit.component'
import { SupplierListComponent } from './resources/supplier/supplier-list/supplier-list.component'
import { SupplierTableComponent } from './resources/supplier/supplier-table/supplier-table.component'
import { TechnicianCreateEditComponent } from './resources/technician/technician-create-edit/technician-create-edit.component'
import { TechnicianListComponent } from './resources/technician/technician-list/technician-list.component'
import { TechnicianTableComponent } from './resources/technician/technician-table/technician-table.component'
import { TypeCreateEditComponent } from './resources/type/type-create-edit/type-create-edit.component'
import { TypeListComponent } from './resources/type/type-list/type-list.component'
import { TypeTableComponent } from './resources/type/type-table/type-table.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserListComponent } from './resources/user/user-list/user-list.component'
import { UserTableComponent } from './resources/user/user-table/user-table.component'
import { UserThumbnailComponent } from './resources/user/user-thumbnail/user-thumbnail.component'

// Register locale data
registerLocaleData(localeFr, 'fr')

export function jwtTokenGetter() {
  return localStorage.getItem('token')
}

// configure Bugsnag asap
const bugsnagClient = bugsnag({
  apiKey: 'd14457e86932acd764331a815c25047e',
  releaseStage: environment.production ? 'production' : 'development',
  notifyReleaseStages: ['production']
})

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    HomeComponent,
    SideMenuComponent,
    TopMenuComponent,
    UserListComponent,
    UserCreateEditComponent,
    UserTableComponent,
    MetaComponent,
    PaginationComponent,
    ConfirmDeleteModalComponent,
    BreadcrumbsComponent,
    MultiSearchComponent,
    GooglePlacesInputComponent,
    Error404Component,
    StatementListComponent,
    StatementDetailComponent,
    StatementCreateEditComponent,
    InvoiceListComponent,
    InvoiceCreateEditComponent,
    MarketListComponent,
    MarketCreateEditComponent,
    AgencyListComponent,
    AgencyDetailComponent,
    AgencyCreateEditComponent,
    ExpenseListComponent,
    ReferentListComponent,
    ReferentCreateEditComponent,
    TechnicianListComponent,
    TechnicianCreateEditComponent,
    RegionalDirectorateListComponent,
    RegionalDirectorateCreateEditComponent,
    NatureListComponent,
    NatureCreateEditComponent,
    TypeListComponent,
    TypeCreateEditComponent,
    ExpenseCreateEditComponent,
    SupplierListComponent,
    SupplierCreateEditComponent,
    StatementTableComponent,
    TypeTableComponent,
    NatureTableComponent,
    TechnicianTableComponent,
    ReferentTableComponent,
    SupplierTableComponent,
    ExpenseTableComponent,
    AgencyTableComponent,
    RegionalDirectorateTableComponent,
    MarketTableComponent,
    InvoiceTableComponent,
    StatusBarComponent,
    UserDetailComponent,
    AddressComponent,
    UserThumbnailComponent,
    OrderLineTableComponent,
    CategoryTableComponent,
    FileInputComponent,
    InvoicePaymentModalComponent,
    DateEditModalComponent,
    ReminderCreateModalComponent,
    MonitoringComponent,
    ReceiptCreateModalComponent,
    ReferentCreateFormComponent,
    StripHtmlPipe,
    RichTextInputComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AngularGooglePlaceModule,
    MyDatePickerModule,
    FlashMessagesModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: environment.tokenWhitelistedDomains
      }
    }),
    ChartsModule,
    CKEditorModule
  ],
  providers: [
    AuthService,
    EventService,
    BreadcrumbService,
    ViewportService,
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
