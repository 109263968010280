import { Component, Input } from '@angular/core'
import { Referent } from '../../../common/interfaces/referent.interface'

@Component({
  selector: 'app-referent-table',
  templateUrl: './referent-table.component.html',
  styleUrls: ['./referent-table.component.scss']
})
export class ReferentTableComponent {
  @Input() referents: Referent[]
  referentToDelete: Referent
}
