import { Component, OnInit, Input } from '@angular/core'
import { Supplier } from '../../../common/interfaces/supplier.interface'

@Component({
  selector: 'app-supplier-table',
  templateUrl: './supplier-table.component.html',
  styleUrls: ['./supplier-table.component.scss']
})
export class SupplierTableComponent {
  @Input() suppliers: Supplier[]
  supplierToDelete: Supplier
}
