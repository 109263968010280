import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class IsAtLeastSecretaryGuard {
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return (
      localStorage.getItem('role') === 'Admin' ||
      localStorage.getItem('role') === 'Secretary'
    )
  }
}
