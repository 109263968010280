import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class IsAtLeastManagerGuard {
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return (
      localStorage.getItem('role') === 'Admin' ||
      localStorage.getItem('role') === 'Secretary' ||
      localStorage.getItem('role') === 'Manager'
    )
  }
}
