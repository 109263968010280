import { Component, OnInit } from '@angular/core'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { RegionalDirectorate } from '../../../common/interfaces/regional-directorate.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-regional-directorate-list',
  templateUrl: './regional-directorate-list.component.html',
  styleUrls: ['./regional-directorate-list.component.scss']
})
export class RegionalDirectorateListComponent implements OnInit {
  paginator: Paginator<RegionalDirectorate>

  filterForm: FormGroup = this.formBuilder.group({
    page: ''
  })

  role: Role
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Directions Régionales'
      }
    ])
  }

  ngOnInit() {
    this.role = this.authService.getRole()

    // We subscribe to queryParams even if there is no filter because delete modal force state reload through adding queryParams.
    this.activatedRoute.queryParams.subscribe(() => {
      this.resourceService
        .list('regional-directorates')
        .subscribe((res: Paginator<RegionalDirectorate>) => {
          this.paginator = res
        })
    })
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
