import { Component, Input } from '@angular/core'
import { User } from '../../../common/interfaces/user.interface'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-user-thumbnail',
  templateUrl: './user-thumbnail.component.html',
  styleUrls: ['./user-thumbnail.component.scss']
})
export class UserThumbnailComponent {
  @Input() user: User

  storagePath: string = environment.storagePath
}
