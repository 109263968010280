import {
  Component,
  EventEmitter,
  Output,
  Renderer2,
  OnChanges,
  Input,
  OnInit
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Statement } from '../../../common/interfaces/statement.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { Setting } from '../../../common/interfaces/setting.interface'
import * as moment from 'moment'

@Component({
  selector: 'app-reminder-create-modal',
  templateUrl: './reminder-create-modal.component.html',
  styleUrls: ['./reminder-create-modal.component.scss'],
  providers: [ResourceService]
})
export class ReminderCreateModalComponent implements OnInit, OnChanges {
  @Input() statement: Statement
  @Output() createReminder: EventEmitter<string> = new EventEmitter()
  @Output() createCanceled: EventEmitter<void> = new EventEmitter()

  form: FormGroup = this.formBuilder.group({
    content: [null, Validators.required]
  })
  showModal: boolean

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private readonly resourceService: ResourceService
  ) {}

  ngOnInit() {
    this.resourceService
      .show('settings', 1)
      .subscribe((settingRes: Setting) => {
        const initialContent =
          `Bonjour,` +
          `\r\n` +
          `\r\n` +
          `Sauf erreur de notre part, nous restons dans l’attente d’attachements correspondant à l’intervention dont les références sont les suivantes :` +
          `\r\n` +
          `\r\n` +
          `${this.statement.projectName}` +
          `\r\n` +
          `${this.statement.nature.name}` +
          `\r\n` +
          `\r\n` +
          `${this.statement.streetNumber} ${this.statement.route} ${this.statement.postalCode} ${this.statement.locality}` +
          `\r\n` +
          `${this.statement.orderNumber}` +
          `\r\n` +
          `\r\n` +
          `Afin de nous permettre de pouvoir lancer la facturation, merci de bien vouloir nous adresser par retour de mail à l’adresse : ${
            settingRes.companyEmail
          } les FSS correspondantes au relevé envoyé le ${moment(
            this.statement.sendingDate
          ).format('DD/MM/YYYY')}` +
          `\r\n` +
          `\r\n` +
          `Dans l’attente de votre retour, je reste disponible si besoin aux numéros indiqués en signature de ce mail.` +
          `\r\n` +
          `\r\n` +
          `Bien cordialement.` +
          `\r\n` +
          `Elodie ROUANET-CHARRITTE` +
          `\r\n` +
          `Secrétariat ${settingRes.companyName}` +
          `\r\n` +
          `\r\n` +
          `${settingRes.companyEmail}` +
          `\r\n` +
          `${settingRes.companyPhone}`

        this.form.get('content').setValue(initialContent)
      })
  }

  ngOnChanges() {
    this.showModal = true
    this.renderer.addClass(document.querySelector('html'), 'is-clipped')
  }

  send() {
    this.createReminder.emit(this.form.value.content)
    this.closeModal()
  }

  cancel() {
    this.createCanceled.emit()
    this.closeModal()
  }

  closeModal() {
    this.showModal = false
    this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
  }
}
