import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core'
import * as moment from 'moment'

import { HasDropdown } from '../../../common/base-components/has-dropdown'
import { Status } from '../../../common/enums/status.enum'
import { OrderLine } from '../../../common/interfaces/order-line.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { Reminder } from '../../../common/interfaces/reminder.interface'
import { AuthService } from '../../../common/services/auth.service'
import { Role } from '../../../common/enums/role.enum'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { appConstants } from '../../../app.constants'
import { FlashMessagesService } from 'angular2-flash-messages'
import { environment } from '../../../../environments/environment'
import { Router } from '@angular/router'
import { StatementType } from '../../../common/enums/statement-type.enum'

@Component({
  selector: 'app-statement-table',
  templateUrl: './statement-table.component.html',
  styleUrls: ['./statement-table.component.scss'],
  providers: [ResourceService]
})
export class StatementTableComponent
  extends HasDropdown
  implements OnInit, OnChanges {
  @Input() statements: Statement[]
  @Input() showAgencies = true
  @Output() orderByChanged: EventEmitter<{
    orderBy: string
    orderByDesc: boolean
  }> = new EventEmitter()

  statementToDelete: Statement
  statementToEditDate: Statement
  dateToEdit: string
  statementToSendReminder: Statement
  selectedStatementIds: number[] = []
  activeStatusEditModal: number
  showEmailModal = false
  showStatusModal = false
  emailLoading = false
  downloadLoading = false

  orderByDesc = false
  orderBy: string

  role: string

  Role = Role
  StatementType = StatementType

  emailForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  })

  constructor(
    elementRef: ElementRef,
    private resourceService: ResourceService,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService
  ) {
    super(elementRef)
  }

  ngOnInit() {
    this.role = this.authService.getRole()
  }

  ngOnChanges() {
    this.calculateStatementAmounts()
    this.calculateLateness()
  }

  calculateStatementAmounts(): void {
    this.statements.forEach((s: Statement) => {
      s.totalOrderLineAmounts = s.orderLines.reduce(
        (prev: number, curr: OrderLine) =>
          prev + curr.rawPrice * curr.rate * curr.quantity,
        0
      )
    })
  }

  calculateLateness(): void {
    this.statements.forEach((s: Statement) => {
      s.isLate =
        s.sendingDate &&
        !s.returnDate &&
        moment(s.sendingDate)
          .add(s.market.returnPeriod, 'days')
          .isBefore(moment())
    })
  }

  toggleSelect(statementId: number) {
    if (this.selectedStatementIds.includes(statementId)) {
      const index = this.selectedStatementIds.indexOf(statementId)
      this.selectedStatementIds.splice(index, 1)
    } else {
      this.selectedStatementIds.push(statementId)
    }

    this.showEmailModal = !!this.selectedStatementIds.length
  }

  async download(statement: Statement) {
    this.downloadLoading = true

    const statementPath: string = await this.resourceService
      .list('statements', { statementIds: statement.id, toZIP: 'true' })
      .toPromise()
      .then((res) => res.path)
      .catch((err) => {
        alert(err)
        delete this.downloadLoading
      })

    delete this.downloadLoading
    window.open(environment.storagePath + statementPath)
  }

  async sendByEmail() {
    this.emailLoading = true

    this.resourceService
      .list('statements', {
        statementIds: this.selectedStatementIds,
        toEmail: this.emailForm.value.email
      })
      .toPromise()
      .then(
        () => {
          this.showEmailModal = false
          this.emailLoading = false

          // Change query params to force reload on lists
          this.flashMessagesService.show(
            `Les relevés sélectionnés ont bien été envoyés par email.`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        },
        (err) => {
          this.showEmailModal = false
          this.emailLoading = false

          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }

  duplicateStatement(statementId: string | number): void {
    this.resourceService.duplicate('statements', statementId).subscribe(
      (createdItem: { id: number }) => {
        this.flashMessagesService.show(
          `Le relevé a été dupliqué avec succès. Veuillez éditer les champs pour le personnaliser.`,
          {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )

        this.router.navigate(['statements', createdItem.id, 'edit'])
      },
      (err) =>
        this.flashMessagesService.show(
          `Une erreur est survenue. Impossible de dupliquer l'élément.`,
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
    )
  }

  onUpdateStatus(statement: Statement, status: Status): void {
    const formData = new FormData()
    formData.set('status', status)
    this.resourceService
      .patch('statements', statement.id, 'update-status', formData)
      .subscribe((updatedStatement: Statement) => {
        statement.status = updatedStatement.status

        delete this.activeStatusEditModal
      })
  }

  order(property: string) {
    if (this.orderBy === property) {
      this.orderByDesc = !this.orderByDesc
    }
    this.orderBy = property
    this.orderByChanged.emit({
      orderBy: this.orderBy,
      orderByDesc: this.orderByDesc
    })
  }

  onUpdateDate(statement: Statement, dateProp: string, date: string): void {
    const formData = new FormData()

    if (date) {
      formData.set('date', date)
    }
    this.resourceService
      .patch(
        'statements',
        statement.id,
        dateProp === 'sendingDate'
          ? 'update-sending-date'
          : 'update-return-date',
        formData
      )
      .subscribe((updatedStatement: Statement) => {
        // Immediately update date and status after this action.
        statement[dateProp] = updatedStatement[dateProp]
        statement.status = updatedStatement.status

        this.calculateLateness()
      })
    delete this.statementToEditDate
  }

  onCreateReminder(statement: Statement, content: string): void {
    const formData = new FormData()
    formData.set('content', content)
    this.resourceService
      .store(`statements/${statement.id}/reminders`, formData)
      .subscribe(
        (reminderRes: Reminder) => {
          statement.reminders.push(reminderRes)

          this.flashMessagesService.show(
            `L'email de relance a bien été envoyé.`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        },
        (err) => {
          this.flashMessagesService.show(
            `Une erreur a eu lieu pendant l'envoi de l'email. Veuillez faire la relance manuellement. Erreur : ` +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    delete this.statementToSendReminder
  }
}
