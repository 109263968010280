import {
  Component,
  Input,
  OnChanges,
  Renderer2,
  Output,
  EventEmitter
} from '@angular/core'

import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Statement } from '../../../common/interfaces/statement.interface'
import * as moment from 'moment'

@Component({
  selector: 'app-date-edit-modal',
  templateUrl: './date-edit-modal.component.html',
  styleUrls: ['./date-edit-modal.component.scss']
})
export class DateEditModalComponent extends HasDatepicker implements OnChanges {
  @Input() statement: Statement
  @Input() dateProp: string
  @Output() updateSendingDate: EventEmitter<string> = new EventEmitter()
  @Output() updateReturnDate: EventEmitter<string> = new EventEmitter()
  @Output() editCanceled: EventEmitter<void> = new EventEmitter()

  showModal: boolean

  form: FormGroup = this.formBuilder.group({
    date: ''
  })

  constructor(private renderer: Renderer2, private formBuilder: FormBuilder) {
    super()
  }

  ngOnChanges() {
    this.showModal = true
    this.renderer.addClass(document.querySelector('html'), 'is-clipped')

    this.form
      .get('date')
      .setValue(
        this.formatStandardDate(
          this.statement[this.dateProp]
            ? moment(this.statement[this.dateProp]).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
        )
      )
  }

  update() {
    const date: string = this.formatMyDatePickerDate(this.form.value.date)

    if (this.dateProp === 'sendingDate') {
      this.updateSendingDate.emit(date)
    } else if (this.dateProp === 'returnDate') {
      this.updateReturnDate.emit(date)
    }
    this.closeModal()
  }

  cancel() {
    this.editCanceled.emit()
    this.closeModal()
  }

  closeModal() {
    this.showModal = false
    this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
  }
}
