import { Component, Input } from '@angular/core'
import { Type } from '../../../common/interfaces/type.interface'

@Component({
  selector: 'app-type-table',
  templateUrl: './type-table.component.html',
  styleUrls: ['./type-table.component.scss']
})
export class TypeTableComponent {
  @Input() types: Type[]
  typeToDelete: Type
}
