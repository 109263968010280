import { Component, Input, OnInit } from '@angular/core'
import { OrderLine } from '../../../common/interfaces/order-line.interface'

@Component({
  selector: 'app-order-line-table',
  templateUrl: './order-line-table.component.html',
  styleUrls: ['./order-line-table.component.scss']
})
export class OrderLineTableComponent implements OnInit {
  @Input() orderLines: OrderLine[]

  ngOnInit() {
    // Sort orderLines alphabetically by number prop.
    this.orderLines = this.orderLines.sort((a: OrderLine, b: OrderLine) => {
      if (a.number < b.number) {
        return -1
      }
      if (a.number > b.number) {
        return 1
      }
      return 0
    })
  }
}
