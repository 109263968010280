import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { Paginator } from '../../../common/interfaces/paginator.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Supplier } from '../../../common/interfaces/supplier.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {
  paginator: Paginator<Supplier>
  queryParams: object
  lastFormValues: any

  filterForm: FormGroup = this.formBuilder.group({
    supplierIds: [],
    page: ''
  })

  constructor(
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Fournisseurs'
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async queryParams => {
      this.queryParams = queryParams
      this.paginator = await this.resourceService
        .list('suppliers', queryParams)
        .toPromise()
        .then((res: Paginator<Supplier>) => res)
    })

    this.filterForm.valueChanges.subscribe(async formValues => {
      // Return to page 1 if we changed our filters (changes in form that are not only the page).
      if (
        this.lastFormValues &&
        formValues !== this.lastFormValues &&
        formValues.page === this.lastFormValues.page
      ) {
        formValues.page = 1
      }
      this.lastFormValues = formValues
      this.router.navigate([], {
        queryParams: formValues
      })
    })
  }

  onMultiSearchChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('supplierIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'suppliers')
          .map((s: SearchResult) => s.id.toString())
      )
  }

  goToPage(pageNumber: number) {
    this.filterForm.get('page').setValue(pageNumber)
  }
}
