import { ViewportScroller } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import * as moment from 'moment'

import { appConstants } from '../../../app.constants'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { Role } from '../../../common/enums/role.enum'
import { AddressObject } from '../../../common/interfaces/address-object.interface'
import { Article } from '../../../common/interfaces/article.interface'
import { Market } from '../../../common/interfaces/market.interface'
import { Nature } from '../../../common/interfaces/nature.interface'
import { OrderLine } from '../../../common/interfaces/order-line.interface'
import { Range } from '../../../common/interfaces/range.interface'
import { Referent } from '../../../common/interfaces/referent.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Statement } from '../../../common/interfaces/statement.interface'
import { Technician } from '../../../common/interfaces/technician.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-statement-create-edit',
  templateUrl: './statement-create-edit.component.html',
  styleUrls: ['./statement-create-edit.component.scss'],
  providers: [ResourceService]
})
export class StatementCreateEditComponent
  extends HasDatepicker
  implements OnInit {
  mode: string
  statement: Statement
  statementToDelete: Statement
  rangeToAddOrderLinesFrom: Range
  natures: Nature[]
  referents: Referent[]
  ranges: Range[]
  selectedAgencyId: number

  initialSearchResults: { [key: string]: string[] } = {
    marketIds: [],
    agencyIds: [],
    technicianIds: [],
    userIds: []
  }
  loading: boolean
  showForceCreationModal: boolean
  showCreateReferentModal: boolean
  showFormErrors: boolean

  form: FormGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: [null, Validators.required],
    arrivingTime: [null, Validators.required],
    startTime: null,
    projectName: [null, Validators.required],
    orderNumber: ['', Validators.required],
    attachment: null,
    removeCurrentAttachment: null,
    streetNumber: '',
    route: '',
    locality: '',
    postalCode: '',
    country: '',
    comments: '',
    addressName: ['', Validators.required],
    userIds: [null, Validators.required],
    marketIds: [null, Validators.required],
    referentId: [null, Validators.required],
    natureId: [null, Validators.required],
    technicianIds: [],
    orderLines: this.formBuilder.array([]),
    forceCreation: false
  })

  get orderLinesFormArray() {
    return this.form.get('orderLines') as FormArray
  }

  role: Role
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private viewportScroller: ViewportScroller
  ) {
    super()
  }

  async ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode
    this.role = this.authService.getRole()

    this.resourceService
      .list('natures', { withoutPagination: 'true' })
      .subscribe((res: Nature[]) => {
        this.natures = res
      })

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/statements',
          label: 'Relevés'
        },
        {
          label: 'Nouveau relevé'
        }
      ])
    } else {
      // Edit mode.
      this.statement = await this.resourceService
        .show('statements', this.activatedRoute.snapshot.params.id)
        .toPromise()
        .then((res: Statement) => res)

      this.ranges = await this.resourceService
        .show('markets', this.statement.market.id)
        .toPromise()
        .then((marketRes: Market) => marketRes.ranges)

      this.form.controls.startDate.setValue(
        this.formatStandardDate(
          moment(this.statement.startDate).format('YYYY-MM-DD')
        )
      )
      this.form.controls.endDate.setValue(
        this.formatStandardDate(
          moment(this.statement.endDate).format('YYYY-MM-DD')
        )
      )
      this.form.controls.arrivingTime.setValue(this.statement.arrivingTime)
      this.form.controls.startTime.setValue(this.statement.startTime),
        this.form.controls.projectName.setValue(this.statement.projectName)
      this.form.controls.orderNumber.setValue(this.statement.orderNumber)
      this.form.controls.streetNumber.setValue(this.statement.streetNumber)
      this.form.controls.route.setValue(this.statement.route)
      this.form.controls.locality.setValue(this.statement.locality)
      this.form.controls.postalCode.setValue(this.statement.postalCode)
      this.form.controls.country.setValue(this.statement.country)
      this.form.controls.comments.setValue(this.statement.comments)
      this.form.controls.addressName.setValue(this.statement.addressName)
      this.form.controls.referentId.setValue(this.statement.referent.id)
      this.form.controls.natureId.setValue(this.statement.nature.id)
      this.form.controls.userIds.setValue(this.statement.user.id)
      this.form.controls.marketIds.setValue(this.statement.market.id)
      this.form.controls.technicianIds.setValue(
        this.statement.technicians.map((t: Technician) => t.id.toString())
      )

      this.initialSearchResults = {
        technicianIds: this.statement.technicians.map((t: Technician) =>
          t.id.toString()
        ),
        userIds: [this.statement.user.id.toString()],
        marketIds: [this.statement.user.id.toString()],
        agencyIds: [this.statement.referent.agency.id.toString()]
      }

      // We load Agency referents to provide quick options on select input.
      this.selectedAgencyId = this.statement.referent.agency.id
      this.referents = await this.resourceService
        .list('referents', {
          agencyIds: this.statement.referent.agency.id.toString(),
          withoutPagination: 'true',
          orderBy: 'name'
        })
        .toPromise()
        .then((res) => res)

      this.statement.orderLines.forEach((oL: OrderLine) => {
        const orderLines = this.form.get('orderLines') as FormArray
        orderLines.push(
          this.formBuilder.group({
            number: [oL.number, Validators.required],
            description: [oL.description, Validators.required],
            category: [oL.category, Validators.required],
            unit: [oL.unit, Validators.required],
            rawPrice: [oL.rawPrice, Validators.required],
            rate: [oL.rate, Validators.required],
            quantity: [oL.quantity, Validators.required],
            id: oL.id
          })
        )
      })

      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/statements',
          label: 'Relevés'
        },
        {
          path: '/statements/' + this.statement.id,
          label: this.statement.orderNumber
        },
        {
          label: 'Editer'
        }
      ])
    }
  }

  submit(): void {
    if (this.form.invalid) {
      this.flashMessagesService.show(
        `Un ou plusieurs champs n'ont pas été correctement remplis. Veuillez vérifier le formulaire.`,
        {
          cssClass: 'notification is-danger',
          timeout: appConstants.FLASH_MESSAGE_TIMEOUT
        }
      )

      this.showFormErrors = true
      return
    }

    this.loading = true

    const formData = new FormData()

    formData.append(
      'startDate',
      this.form.value.startDate
        ? this.formatMyDatePickerDate(this.form.value.startDate)
        : null
    )
    formData.append(
      'endDate',
      this.form.value.endDate
        ? this.formatMyDatePickerDate(this.form.value.endDate)
        : null
    )
    formData.append('arrivingTime', this.form.value.arrivingTime)
    formData.append('startTime', this.form.value.startTime || '')
    formData.append('projectName', this.form.value.projectName)
    formData.append('orderNumber', this.form.value.orderNumber)
    formData.append('comments', this.form.value.comments)

    if (this.form.value.forceCreation) {
      formData.append('forceCreation', 'true')
    }

    if (this.form.value.attachment) {
      formData.append(
        'attachment',
        this.form.value.attachment.content,
        this.form.value.attachment.name
      )
    } else if (this.form.value.removeCurrentAttachment) {
      formData.append('removeCurrentAttachment', 'true')
    }

    // Address.
    if (this.form.value.addressName) {
      formData.append('addressName', this.form.value.addressName)
      formData.append('streetNumber', this.form.value.streetNumber)
      formData.append('route', this.form.value.route)
      formData.append('locality', this.form.value.locality)
      formData.append('postalCode', this.form.value.postalCode)
      formData.append('country', this.form.value.country)
    }

    // Relations.
    formData.append('userId', this.form.value.userIds)
    formData.append('marketId', this.form.value.marketIds)
    formData.append('referentId', this.form.value.referentId)
    formData.append('natureId', this.form.value.natureId)
    if (this.form.value.technicianIds) {
      this.form.value.technicianIds.forEach((technicianId: string) => {
        formData.append('technicianIds', technicianId)
      })
    }
    if (this.form.value.orderLines) {
      this.form.value.orderLines.forEach(
        (orderLine: {
          description: string
          number: string
          category: string
          unit: string
          rawPrice: number
          rate: number
          quantity: number
          id?: number
        }) => {
          formData.append(
            'orderLineObjects',
            JSON.stringify({
              description: orderLine.description,
              number: orderLine.number,
              category: orderLine.category,
              rawPrice: orderLine.rawPrice,
              rate: orderLine.rate,
              unit: orderLine.unit,
              quantity: orderLine.quantity.toFixed(2),
              id: orderLine.id
            })
          )
        }
      )
    }

    if (this.mode === 'create') {
      this.resourceService.store('statements', formData).subscribe(
        () => {
          this.loading = false
          this.showForceCreationModal = false
          this.flashMessagesService.show(`Le relevé a bien été enregistré`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })

          this.router.navigate(['/statements'])
        },
        (err: HttpErrorResponse) => {
          this.loading = false

          // Duplicate statement error : open modal to prompt force creation.
          if (err.error.statusCode === 409) {
            this.showForceCreationModal = true
          } else {
            this.showForceCreationModal = false
            this.flashMessagesService.show(
              'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        }
      )
    } else {
      // Edit mode
      this.resourceService
        .update('statements', this.statement.id, formData)
        .subscribe(
          () => {
            this.loading = false
            this.flashMessagesService.show(`Le relevé a bien été mis à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })

            this.router.navigate(['/statements', this.statement.id])
          },
          (err) => {
            this.loading = false
            this.flashMessagesService.show(
              'Error ' + JSON.stringify(err.error.message),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  addOrderLinesFormRangeArticles(range: Range): void {
    const orderLines = this.form.get('orderLines') as FormArray
    range.articles.forEach((a: Article) => {
      orderLines.push(
        this.formBuilder.group({
          number: [a.number, Validators.required],
          description: [a.description, Validators.required],
          category: [a.category, Validators.required],
          unit: [a.unit, Validators.required],
          rawPrice: [a.rawPrice, Validators.required],
          rate: [range.rate, Validators.required],
          quantity: [a.defaultQuantity, Validators.required]
        })
      )
    })
    this.flashMessagesService.show(
      `Les articles de la série ${range.name} ont été ajoutés`,
      {
        cssClass: 'notification is-success',
        timeout: appConstants.FLASH_MESSAGE_TIMEOUT
      }
    )
    this.viewportScroller.scrollToAnchor('add-order-line-link')
  }

  forceCreation() {
    this.form.get('forceCreation').setValue(true)
    this.submit()
  }

  addOrderLine(): void {
    const orderLines = this.form.get('orderLines') as FormArray
    orderLines.push(
      this.formBuilder.group({
        number: ['', Validators.required],
        description: ['', Validators.required],
        category: ['', Validators.required],
        unit: ['', Validators.required],
        rawPrice: ['', Validators.required],
        rate: [1, Validators.required],
        quantity: ['', Validators.required]
      })
    )
  }

  removeOrderLine(index: number): void {
    const orderLines = this.form.get('orderLines') as FormArray
    orderLines.removeAt(index)
  }

  deleteOrderLinesWithZeroQuantity(): void {
    const orderLines = this.form.get('orderLines') as FormArray

    const emptyOrderLines: number= (orderLines.value as OrderLine[])
      .filter((item) => !item.quantity)
      .length

    if (emptyOrderLines) {
      for (let i = 0; i < emptyOrderLines; i++) {
        // As FormArrays removals are one by one by index, we have to iterate search too as indexes changes while deleting items.
        this.orderLinesFormArray.removeAt((this.orderLinesFormArray.value as OrderLine[]).findIndex(oL => !oL.quantity))
      }

      this.flashMessagesService.show(
        emptyOrderLines> 1
          ? `${emptyOrderLines} lignes ont été correctement effacées.`
          : `1 ligne a été correctement effacée.`,
        {
          cssClass: 'notification is-success',
          timeout: appConstants.FLASH_MESSAGE_TIMEOUT
        }
      )
    } else {
      this.flashMessagesService.show(`Aucune ligne a effacer.`, {
        cssClass: 'notification is-warning',
        timeout: appConstants.FLASH_MESSAGE_TIMEOUT
      })
    }
  }

  onMultiSearchChanged(
    searchResults: SearchResult[],
    propName: string,
    resourceName: string
  ) {
    this.form
      .get(propName)
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === resourceName)
          .map((s: SearchResult) => s.id.toString())
      )
  }

  onReferentStored(createdReferent: Referent): void {
    this.showCreateReferentModal = false
    this.flashMessagesService.show(`Le donneur d'ordres a bien été crée`, {
      cssClass: 'notification is-success',
      timeout: appConstants.FLASH_MESSAGE_TIMEOUT
    })
    // Integrate and select freshly created referent.
    this.referents.push(createdReferent)
    this.form.get('referentId').setValue(createdReferent.id)
  }

  // User can choose one of Agency Referents.
  async onAgencyChanged(searchResults: SearchResult[]) {
    const selectedAgency = searchResults.find(
      (s: SearchResult) => s.resourceName === 'agencies'
    )
    if (selectedAgency) {
      this.selectedAgencyId = selectedAgency.id
      this.referents = await this.resourceService
        .list('referents', {
          agencyIds: selectedAgency.id.toString(),
          withoutPagination: 'true',
          orderBy: 'name'
        })
        .toPromise()
        .then((res) => res)
    } else {
      delete this.selectedAgencyId
      this.referents = []
      this.form.get('referentId').reset()
    }
  }

  // Get Ranges and Articles from Market to create OrderLines.
  async onMarketChanged(searchResults: SearchResult[]) {
    const selectedMarket: SearchResult = searchResults.find(
      (s: SearchResult) => s.resourceName === 'markets'
    )
    if (selectedMarket) {
      const market: Market = await this.resourceService
        .show('markets', selectedMarket.id)
        .toPromise()
        .then((res) => res)

      this.ranges = market.ranges
    }
  }

  onUpdateFormAddress(addressObject: AddressObject, prefix?: string): void {
    this.form.controls[
      prefix ? prefix + 'AddressName' : 'addressName'
    ].setValue(addressObject.addressName)
    this.form.controls[
      prefix ? prefix + 'StreetNumber' : 'streetNumber'
    ].setValue(addressObject.streetNumber ? addressObject.streetNumber : '')
    this.form.controls[prefix ? prefix + 'Route' : 'route'].setValue(
      addressObject.route ? addressObject.route : ''
    )
    this.form.controls[prefix ? prefix + 'Locality' : 'locality'].setValue(
      addressObject.locality ? addressObject.locality : ''
    )
    this.form.controls[prefix ? prefix + 'PostalCode' : 'postalCode'].setValue(
      addressObject.postalCode ? addressObject.postalCode : ''
    )
    this.form.controls[prefix ? prefix + 'Country' : 'country'].setValue(
      addressObject.country ? addressObject.country : ''
    )
  }
}
