import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter
} from '@angular/core'
import { HTMLInputEvent } from '../../../common/interfaces/html-input-event.interface'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput') fileInputEl: ElementRef
  @Input() accept: string
  @Input() label = 'Fichier'
  @Input() placeholder = 'Ajouter un fichier'
  @Input() currentFilePlaceholder = 'Fichier importé'
  @Input() currentFile: string
  @Input() required: boolean
  @Input() boxedStyle = false
  @Output() fileSelected: EventEmitter<{
    name: string
    content: File
  }> = new EventEmitter()
  @Output() uploadedFileRemoved: EventEmitter<void> = new EventEmitter()
  @Output() currentFileRemoved: EventEmitter<void> = new EventEmitter()

  file: { name: string; content: File }
  storagePath: string = environment.storagePath

  constructor() {}

  ngOnInit() {}

  // Triggers on adding image
  fileInputEvent(fileInput: HTMLInputEvent) {
    this.file = {
      name: fileInput.target.files[0].name,
      content: this.fileInputEl.nativeElement.files.item(0)
    }

    this.fileSelected.emit(this.file)
  }

  removeUploadedFile() {
    delete this.file
    this.uploadedFileRemoved.emit()
  }

  removeCurrentFile() {
    delete this.currentFile
    this.currentFileRemoved.emit()
  }
}
